import {
    getFinalRankingChannel,
    IGameCompDetailsChannel,
    IRsc,
    IScheduleChannel,
    useCurrentGamesH2HData,
    useCurrentGamesTimingData,
    useScheduleData,
} from "stuffjs";
import { useFinalRankingChannelData } from "stuffjs/src/composables/final-ranking-composable";
import { computed, Ref } from "vue";
import { useChannel } from "vue-uc";

import { getLrStoreStateRefs, useLRStoreRefs } from "../store/lr-store";
import { useCompDetailsChannelName, useResultChannelName } from "./store-composables";

export const useChannelFromRscProp = (rsc: Ref<string>) => {
    const { resultChannelType } = getLrStoreStateRefs();
    const { resultChannelName } = useResultChannelName(resultChannelType, rsc);

    return { channelName: resultChannelName, channelType: resultChannelType };
};

export const useCurrentFinalRankingChannel = (finalRankingRsc: Ref<string>) => {
    const lrStoreRefs = useLRStoreRefs();
    const channelName = computed(() => getFinalRankingChannel(lrStoreRefs.tournamentId.value, finalRankingRsc.value));
    return useFinalRankingChannelData(useChannel(channelName));
};

export const useCurrentGamesH2HChannel = () => {
    const lrStoreRefs = useLRStoreRefs();
    return useCurrentGamesH2HData(useChannel(computed(() => lrStoreRefs.channelNames?.value?.current)));
};

export const useCurrentGamesTimingChannel = () => {
    const lrStoreRefs = useLRStoreRefs();
    return useCurrentGamesTimingData(useChannel(computed(() => lrStoreRefs.channelNames?.value?.current)));
};

export const useScheduleChannel = (scheduleChannelName: Ref<string>) =>
    useScheduleData(useChannel<IScheduleChannel>(scheduleChannelName));

export const useCurrentScheduleChannel = () => {
    const lrStoreRefs = useLRStoreRefs();
    return useScheduleData(useChannel(computed(() => lrStoreRefs.channelNames?.value?.schedule)));
};

export const useCompDetailsChannel = (rsc: Ref<IRsc>) => {
    const channelName = useCompDetailsChannelName(computed(() => rsc.value?.Value));
    const compDetailsChannel = useChannel<IGameCompDetailsChannel>(channelName);
    const competitors = computed(() => compDetailsChannel.value?.Competitors);
    const officials = computed(() => compDetailsChannel.value?.Officials);

    return { channelName, compDetailsChannel, competitors, officials };
};
