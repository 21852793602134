import { merge } from "lodash-es";

export const DEFAULT_LANGUAGE_CODE = "en";

class TranslationController {
    private baseLng = DEFAULT_LANGUAGE_CODE;
    private lngCode = this.baseLng;
    private stringsPerLng = {};

    /**
     * Method to fill the manager with run time strings.
     *
     * @param lngCode - The language code to fill strings into.
     * @param strings - The key/value pair of strings.
     * @param applyOnBaseLng - Decide whether the base language is merged into a given language to keep a text for every key occurence.
     */
    setStrings(lngCode, strings = {}, applyOnBaseLng = true): void {
        const existingLngStr = this.stringsPerLng[lngCode];

        const lngCodeStrings = {};
        if (applyOnBaseLng) {
            merge(lngCodeStrings, this.stringsPerLng[this.baseLng], strings);
        } else {
            merge(lngCodeStrings, strings);
        }

        if (existingLngStr) {
            merge(existingLngStr, lngCodeStrings);
        } else {
            this.stringsPerLng[lngCode] = lngCodeStrings;
        }
    }

    get strings() {
        return this.stringsPerLng;
    }
}

const TranslationManager = new TranslationController();

const getStrByLng = <T extends { [x: string]: string }>(lngCode = DEFAULT_LANGUAGE_CODE): T =>
    TranslationManager.strings[lngCode];

export { getStrByLng, TranslationManager };
