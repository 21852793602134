<template>
    <td class="lr__name-column">
        <div class="name-container">
            <VucName
                class="name--default"
                :name="name ?? competitor.Name"
                :first-name="isNil(name) ? firstName : undefined"
            ></VucName>

            <div v-if="hasMarkers" class="marker">
                <span v-if="qMarkValue" class="q-mark">{{ qMarkValue }}</span>
                <span v-if="irmVal" class="irm-mark">{{ irmVal }}</span>
                <span v-if="competitor.IsWinner" class="winner-mark">{{ $getStrByLng().LABEL_WINNER }}</span>
                <slot name="markers"></slot>
            </div>
        </div>
        <div v-if="displayTeamMembers && competitor.Children" class="member-list">
            <template v-for="member in competitor.Children" :key="member.AthleteId">
                <VucName tag="span" :name="member.Name" :first-name="member.FirstNameShort"></VucName>
            </template>
        </div>
    </td>
</template>

<script setup lang="ts">
import { isNil } from "lodash-es";
import { ICommonCompetitor } from "stuffjs";
import { computed, PropType, useSlots } from "vue";
import { VucName } from "vue-uc";

import { useLiveResultStandingData } from "../../composables/standing-lr-composable";

const props = defineProps({
    competitor: {
        type: Object as PropType<ICommonCompetitor>,
        default: (): ICommonCompetitor => {
            return {};
        },
    },
    displayShortName: {
        type: Boolean,
        default: false,
    },
    displayTeamMembers: {
        type: Boolean,
        default: true,
    },
    displayWinner: {
        type: Boolean,
        default: false,
    },
    name: String,
    irm: String,
    qMark: String,
});

const { getIrmCodeOrName, getQualificationInfo } = useLiveResultStandingData();
const slots = useSlots();
const irmVal = computed(() => getIrmCodeOrName(props.irm || props.competitor?.IRM));

const qMarkValue = computed(() => {
    const qMark = props.qMark ?? props.competitor?.QualificationMark;
    const qMarkInfo = getQualificationInfo(qMark);
    return qMarkInfo?.Name ?? qMarkInfo?.Code ?? qMark;
});
const hasMarkers = computed(
    () => irmVal.value || qMarkValue.value || (props.displayWinner && props.competitor.IsWinner) || !!slots.markers
);

const firstName = computed(() =>
    props.displayShortName ? props.competitor.FirstNameShort : props.competitor.FirstName
);
</script>

<style lang="scss">
.lr__name-column {
    td.name--default,
    th.name--default {
        text-align: left;
    }

    .name-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: var(--font-condensed);
        line-height: 1;
        gap: 10px;

        @include max-mobile {
            align-items: flex-start;
        }
    }

    .marker {
        display: flex;
        gap: 5px;

        > span {
            background: var(--font-gradient-1);
            color: var(--font-color-1-contrast);
            font-family: var(--font-condensed);
            margin: 0;
            padding: 6px;
            font-size: 14px;
            line-height: 1;
        }
    }

    .member-list {
        display: flex;
        font-size: 0.8em;
        color: var(--font-color-6);
        line-height: 1;

        border-top: 1px solid rgba(0, 0, 0, 0.125);
        padding-top: 10px;
        margin-top: 10px;

        &:empty {
            display: none;
        }

        @include max-tablet {
            flex-direction: column;
        }

        > .vuc-name {
            @include max-tablet {
                margin-bottom: 5px;
            }

            &:not(:last-child) {
                &:after {
                    content: ",";
                    margin-right: 3px;
                    color: var(--font-1);
                }
            }
        }
    }
}
</style>
