const JSON_DIFF_TYPES = {
    DELETE: "_jsondiff_del",
    DIFF_TYPE: "__jsondiff_t",
    DIFF_TYPE_ARRAY: "a",
    DIFF_TYPE_ARRAY_INSERT: "i",
    DIFF_TYPE_ARRAY_DELETE: "d",
};

export const VuePushClientDiffParser = {
    applyPatch(channel: object, diff): void {
        this.patchObject(channel, diff);
    },

    patchObject(obj, diff): void {
        for (const key in diff) {
            const newValue = diff[key];

            if (newValue === JSON_DIFF_TYPES.DELETE) {
                delete obj[key];
                // obj[key] = undefined;
            } else if (typeof newValue === "object") {
                if (!Array.isArray(newValue)) {
                    if (newValue === null) {
                        obj[key] = newValue;
                    } else if (newValue[JSON_DIFF_TYPES.DIFF_TYPE] === JSON_DIFF_TYPES.DIFF_TYPE_ARRAY) {
                        this.patchArray(obj[key], newValue);
                    } else {
                        const oldValue = obj[key];

                        if (typeof oldValue === "object" && oldValue !== null) {
                            this.patchObject(oldValue, newValue);
                        } else {
                            obj[key] = newValue;
                        }
                    }
                } else {
                    obj[key] = newValue;
                }
            } else {
                obj[key] = newValue;
            }
        }
    },

    patchArray(node, diff): void {
        const updates = diff.u;
        if (updates) {
            for (const key in updates) {
                const newValue = updates[key];

                if (typeof newValue === "object") {
                    if (newValue[JSON_DIFF_TYPES.DIFF_TYPE] === JSON_DIFF_TYPES.DIFF_TYPE_ARRAY) {
                        this.patchArray(node[key], newValue);
                    } else {
                        this.patchObject(node[key], newValue);
                    }
                } else {
                    node[key] = newValue;
                }
            }
        }

        const inserts = diff[JSON_DIFF_TYPES.DIFF_TYPE_ARRAY_INSERT];
        if (inserts) {
            node.splice(node.length, 0, ...inserts);
        } else {
            const deletes = diff[JSON_DIFF_TYPES.DIFF_TYPE_ARRAY_DELETE];
            if (deletes !== undefined) {
                node.splice(node.length - deletes, deletes);
            }
        }
    },
};
