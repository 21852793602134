<template>
    <div class="total-medal-standings-nations">
        <h1>Total Medal Standing</h1>
        <LrPlainTable v-if="sortedCompetitors && sortedCompetitors?.length > 0">
            <template #header>
                <tr>
                    <RankColumnHead v-stick-left></RankColumnHead>
                    <NationColumnHead v-stick-left></NationColumnHead>
                    <NameColumnHead></NameColumnHead>
                    <LrMedalsHeadColumn></LrMedalsHeadColumn>
                    <th class="total-rank">Rank by Total</th>
                </tr>
            </template>
            <template #body>
                <tr v-for="comp in sortedCompetitors" :key="comp.Id">
                    <RankColumn v-stick-left :competitor="comp"></RankColumn>
                    <NationColumn v-stick-left :competitor="comp"></NationColumn>
                    <NameColumn :competitor="comp"></NameColumn>
                    <LrMedalsColumn :competitor="comp" :prefix="'All'"></LrMedalsColumn>
                    <td class="total-rank">{{ comp.Stats?.RankTotal }}</td>
                </tr>
            </template>
        </LrPlainTable>
        <LrPlaceHolder v-else message="There is no medal standing available right now."></LrPlaceHolder>
    </div>
</template>

<script lang="ts" setup>
import {
    LrMedalsColumn,
    LrMedalsHeadColumn,
    LrPlaceHolder,
    LrPlainTable,
    NameColumn,
    NameColumnHead,
    NationColumn,
    NationColumnHead,
    RankColumn,
    RankColumnHead,
} from "lr-core";
import { IMedalTableChannel, useMedalTableChannelData } from "stuffjs";
import { useChannel } from "vue-uc";

import { useEntryPageRefs } from "../store/entry-page.store";

const { medalChannelName } = useEntryPageRefs();
const medalChannel = useChannel<IMedalTableChannel>(medalChannelName);
const { sortedCompetitors } = useMedalTableChannelData(medalChannel);
</script>

<style lang="scss">
.total-medal-standings-nations {
    .total-rank {
        min-width: 120px;
        text-align: center;
        line-height: 1;
        font-size: 20px;
        font-family: var(--font-bold);
    }

    .lr__medal-head-all .total-medals {
        bottom: 2px;
    }

    .lr__component-table {
        table {
            color: var(--font-color-1-contrast);

            thead {
                background-color: transparent;
                th {
                    font-size: 20px;

                    @include max-mobile {
                        padding-left: 10px;
                        padding-right: 10px;

                        &.x--stick-left {
                            background-color: var(--bg-3);
                        }
                    }
                }
            }

            tbody {
                tr {
                    height: 40px;

                    &:nth-child(even) {
                        td {
                            background-color: transparent;
                        }
                    }

                    &:nth-child(odd) {
                        td {
                            background-color: transparent;
                        }
                    }

                    td {
                        border-top: 1px solid rgba(255, 255, 255, 0.25);
                        padding: 20px 12px;

                        @include max-mobile {
                            padding: 20px 10px;

                            &.x--stick-left {
                                background-color: var(--bg-3);
                            }
                        }

                        &.lr__rank-column {
                            text-align: center;
                            line-height: 1.6;
                            font-family: var(--font-bold);
                            font-size: 20px;
                        }

                        &.medal-count {
                            text-align: center;
                            font-family: var(--font-regular);
                            font-size: 20px;

                            &:nth-child(4n + 3) {
                                color: var(--font-color-5);
                            }
                        }
                    }
                }
            }
        }

        @include max-mobile {
            border: none;
        }
    }

    h1 {
        font-family: var(--font-bold);
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: var(--font-color-5);
    }
}
</style>
