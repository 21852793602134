export const isBoolean = (value): boolean => typeof value === "boolean";

export const isNumber = (value): boolean => isFinite(value) && +value === value;

export const isNumeric = (value) => !isNaN(value) && !isNaN(parseInt(value, 10));

export const isString = (value): boolean => typeof value === "string";

export const isMeaningfulString = (value): boolean => isString(value) && value.replace(/\s/g, "").length > 0;

export const isFunction = (value): boolean => typeof value === "function";

export const isNil = (value): boolean => value == null;

export const isArray = (value): boolean => Array.isArray(value);

export const isDefined = (value): boolean => value !== undefined && value !== null;
