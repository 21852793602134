export const getCombinedValue = (valueA = "", valueB = "", delimiter = " "): string =>
    valueA && valueB ? valueA + delimiter + valueB : valueA ?? valueB ?? "";

export const getDefaultValue = (value, fallback = 0): string => (value !== undefined ? value : fallback);

export const addLeadingZero = (val: string | number, places = 2) => val?.toString()?.padStart(places, "0");

export const handleWarn = (warning) => {
    console.warn(warning);
};

export const isEmptyObject = (obj: object): boolean => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
};

export const cloneObject = (obj: object): object => JSON.parse(JSON.stringify(obj));

export const isNodeEnvironment = () => typeof process === "object" && typeof require === "function";

// @ts-ignore
export const isElectronRenderer = () => typeof process === "object" && process?.type === "renderer";
