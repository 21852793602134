import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMediaQuery } from "@vueuse/core";
import FloatingVue from "floating-vue";
import { merge } from "lodash-es";
import { createPinia } from "pinia";
import { DEFAULT_LANGUAGE_CODE, getStrByLng, TranslationManager } from "stuffjs";
import { App, createApp } from "vue";
import { createRouter, createWebHashHistory, Router, RouteRecordRaw } from "vue-router";

import lrEngDefaults from "../../translation/LR_TRANSLATIONS_RESOURCE_KEYS.json";

export const registerAppModules = (params: {
    title: string;
    routes?: RouteRecordRaw[];
    onRouteChangeScrollElement?: () => HTMLElement;
    translations: { [x: string]: string };
    rootApp: any;
}): { router: Router; application: App<Element> } => {
    document.title = `${params.title}`;

    TranslationManager.setStrings(DEFAULT_LANGUAGE_CODE, merge(lrEngDefaults, params.translations));
    const application = createApp(params.rootApp);
    application.config.globalProperties.$getStrByLng = getStrByLng;

    let router;
    if (params.routes) {
        router = createRouter({
            history: createWebHashHistory(),
            routes: params.routes,
            ...(params.onRouteChangeScrollElement && {
                scrollBehavior() {
                    // const isMediumSized = useMediaQuery("(max-width: 768px)");
                    // if (isMediumSized.value) {
                    params.onRouteChangeScrollElement()?.scrollIntoView({ behavior: "smooth", block: "start" });
                    // }
                },
            }),
        });
        application.use(router);
    }

    application.use(createPinia());
    application.use(FloatingVue, {
        themes: {
            "lr-tooltip": { $extend: "tooltip" },
            "lr-dropdown": { $extend: "dropdown" },
        },
    });

    application.component("font-awesome-icon", FontAwesomeIcon);
    return { router, application };
};
