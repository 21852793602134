<template>
    <th class="rank--default">
        <slot>{{ $getStrByLng().HEADER_RANK }}</slot>
    </th>
</template>

<script setup lang="ts"></script>

<style>
th.rank--default {
    text-align: left;
}
</style>
