import { defineStore } from "pinia";
import { getApplicationName } from "stuffjs";

import { LR_QUERY_NAMES, MODES, NAMESPACE_ENVIRONMENTS } from "../enums/lr-core.enumerations";
import { ILocalConfig } from "../interfaces/app.config";
declare const APP_NAME;

export const useConfigurationStore = defineStore("ConfigurationStore", () => {
    const qParams = new URLSearchParams(window.location.search);

    const createConfig = (options: { sportCode: string }) => {
        const configuration: ILocalConfig = {};
        configuration.mode = (qParams.get(LR_QUERY_NAMES.MODE) ?? import.meta.env.MODE) as MODES;

        configuration.eventURL = "https://riyadh2023.com";

        switch (configuration.mode) {
            case MODES.DEV:
                configuration.environment = NAMESPACE_ENVIRONMENTS.DEV;
                configuration.sportSelectionURL = "https://NOT_AVAILABLE_IN_DEV.html";
                configuration.liveResultsDomain = "https://NOT_AVAILABLE_IN_DEV.html";
                break;
            case MODES.UAT:
                configuration.applicationNamePrefix = "WCG2023";
                configuration.environment = NAMESPACE_ENVIRONMENTS.TEST;
                configuration.sportSelectionURL = "https://uat-riyadh23.sportresult.com";
                configuration.liveResultsDomain = "uat-riyadh23.sportresult.com";

                break;
            case MODES.PROD:
                configuration.applicationNamePrefix = "WCG2023";
                configuration.environment = NAMESPACE_ENVIRONMENTS.PROD;
                configuration.sportSelectionURL = "https://riyadh23.sportresult.com";
                configuration.liveResultsDomain = "riyadh23.sportresult.com";
                break;
            default:
                configuration.applicationNamePrefix = "WCG2023";
                configuration.environment = NAMESPACE_ENVIRONMENTS.PROD;
                configuration.sportSelectionURL = "https://riyadh23.sportresult.com";
                configuration.liveResultsDomain = "riyadh23.sportresult.com";
                break;
        }

        const targetHost = qParams.get(LR_QUERY_NAMES.HOST) ?? undefined;

        if (targetHost) {
            // Connect to an entirely different host.
            configuration.host = targetHost;
            configuration.configPath = undefined;
            configuration.useHTTPS =
                (qParams.get(LR_QUERY_NAMES.HOST) !== undefined && qParams.get(LR_QUERY_NAMES.USEHTTPS) === "true") ??
                configuration.useHTTPS;
            configuration.cachingClusterURL = undefined;
        }

        // query-params > local-config's 'defaultNamespace' > 'sportCode' + local-config's 'mode'
        configuration.defaultNamespace = (
            qParams.get(LR_QUERY_NAMES.NAMESPACE) ??
            configuration.defaultNamespace ??
            getApplicationName(
                options.sportCode?.toUpperCase(),
                configuration.environment,
                configuration.applicationNamePrefix,
                configuration.applicationNameSuffix
            )
        )?.toUpperCase();

        // Determine Frontend identifier for server metrics in pushserver
        configuration.frontendIdentifier = `${APP_NAME}:${configuration.defaultNamespace}`.toLowerCase();
        configuration.globalNamespace = getApplicationName(
            "GLO",
            configuration.environment,
            configuration.applicationNamePrefix,
            configuration.applicationNameSuffix
        )?.toUpperCase();

        //@ts-ignore
        window.lrConfig = configuration;
        return configuration;
    };

    return { createConfig };
});
