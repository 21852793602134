<template>
    <td class="lr__rank-column rank--default">
        {{ rankOrIRM }}
    </td>
</template>

<script lang="ts">
import { isNil } from "lodash-es";
import { ICompetitorDetail, IMedalCompetitor } from "stuffjs";
import { defineComponent, PropType } from "vue";

import { useLiveResultStandingData } from "../../composables/standing-lr-composable";

export default defineComponent({
    name: "RankColumn",
    setup() {
        const { getIrmCode } = useLiveResultStandingData();
        return { getIrmCode };
    },
    props: {
        competitor: {
            type: Object as PropType<ICompetitorDetail | IMedalCompetitor>,
            default: (): ICompetitorDetail | IMedalCompetitor => {
                return {};
            },
        },
        rank: [String, Number],
        placeholder: {
            type: String,
            default: "",
        },
    },
    computed: {
        rankOrIRM(): string | number {
            if (!isNil(this.competitor?.Rank) || this.rank) {
                return this.competitor?.Rank ?? this.rank;
            } else {
                return this.placeholder;
            }
        },
    },
});
</script>

<style lang="scss">
td.rank--default,
th.rank--default {
    text-align: center;
}
</style>
