export enum DefaultRoutes {
    Default = "default",
    Schedule = "schedule",
    PoolStandings = "poolStandings",
    Result = "result",
    Medals = "medals",
    MedalsStandings = "medalStandings",
    Medallists = "medallists",
    FinalRanking = "finalRanking",
    Records = "records",
    ResultBooks = "resultBooks",
    WorldRecords = "world",
    GamesRecords = "games",
    BrokensRecords = "broken",
}
