import { merge } from "lodash-es";
import { defineStore, storeToRefs } from "pinia";
import PushServerClient from "pushserver-client/dist/pushServerClient";
import {
    CHANNELS_NAMES,
    createDevPushClientOptions,
    createProdPushClientOptions,
    createUatPushClientOptions,
    DEFAULT_LANGUAGE_CODE,
    getCompStrucChannel,
    getCurrentChannel,
    getEntriesChannel,
    getEventBinariesChannel,
    getLiveChannel,
    getMedallistsChannel,
    getMedalTableChannel,
    getRecordsChannel,
    getRecordSetClassesChannel,
    getScheduleChannel,
    getStandingDataChannel,
    ISport,
    IStandingDataChannel,
    RecordAreaType,
    ResultChannelTypes,
    retrieveChannelContent,
    SportTypes,
    subscribeChannel,
    substituteChannel,
    useChannelManagerRefs,
    useChannelStore,
} from "stuffjs";
import { computed, ComputedRef, reactive, toRefs, watch } from "vue";

import { LR_QUERY_NAMES, MODES } from "../enums/lr-core.enumerations";
import { ILocalConfig, ILRFrontendOptions } from "../interfaces/app.config";
import { useConfigurationStore } from "./config-store";

export const LR_MAIN_STORE_KEY = "LR-CORE";

interface ILrState {
    sport?: ISport;
    frontendOptions?: ILRFrontendOptions;

    resultChannelType: ComputedRef<ResultChannelTypes>;
    language?: string;
    config: ILocalConfig;
    highlightedBracketId: string; // updated on hover/mouseover in bracket and causes corresponding items to highlight at the same time.
}

const qParams = new URLSearchParams(window.location.search);

export const useLRStore = defineStore(LR_MAIN_STORE_KEY, () => {
    // https://github.com/vuejs/pinia/issues/978 StoreSetup
    // https://pinia.vuejs.org/introduction.html#basic-example
    // <editor-fold desc="State">

    const state = reactive<ILrState>({
        resultChannelType: computed(() => {
            switch (state.sport?.sportType) {
                case SportTypes.Combat:
                    return ResultChannelTypes.COMBAT;
                case SportTypes.Timing:
                    return ResultChannelTypes.TIMING;
                case SportTypes.Racquet:
                    return ResultChannelTypes.RACQUET;
                case SportTypes.Scoring:
                    return ResultChannelTypes.TIMING; // could be incorrect
                case SportTypes.Team:
                    return ResultChannelTypes.GAME;
                default:
                    return ResultChannelTypes.NONE;
            }
        }),
        config: {},
        frontendOptions: {},
        highlightedBracketId: undefined,
    });
    // </editor-fold>

    // <editor-fold desc="Getters">

    const currentEventChannel = computed(() => {
        if (state.config.defaultNamespace) {
            return subscribeChannel(CHANNELS_NAMES.CURRENT_EVENT);
        }
        return undefined;
    });

    const currentEventData = computed(() => currentEventChannel.value?.content);

    const { PS_ConnectionStatus: PushServerConnectionStatus } = useChannelManagerRefs();

    const tournamentId = computed(
        () => qParams.get(LR_QUERY_NAMES.EVENT) ?? currentEventData?.value?.EventId?.toUpperCase(),
    );

    const channelNames = computed(() => {
        if (tournamentId.value && state.config.defaultNamespace) {
            const { value: tId } = tournamentId;
            const { schedule } = state.frontendOptions;
            return {
                eventBinaries: getEventBinariesChannel(tId),
                standings: getStandingDataChannel(tId),
                schedule: getScheduleChannel(tId, schedule?.channelSuffix),
                current: getCurrentChannel(tId),
                live: getLiveChannel(tId),
                entries: getEntriesChannel(tId),
                medalTable: getMedalTableChannel(tId),
                medallists: getMedallistsChannel(tId),
                compstruc: getCompStrucChannel(tId),
                records: (recordType: RecordAreaType) => getRecordsChannel(tId, recordType),
                brokenRecords: getRecordSetClassesChannel(tId),
            };
        }
        return undefined;
    });

    const standingData = retrieveChannelContent<IStandingDataChannel>(computed(() => channelNames.value?.standings));
    // </editor-fold>

    // <editor-fold desc="Actions">
    async function initStore(sport: ISport, options: ILRFrontendOptions = {}) {
        const { connect } = useChannelStore();
        state.config = useConfigurationStore().createConfig({ sportCode: sport.sportCode });

        state.sport = sport;
        // filter 'null' properties from the fetched 'local.config.json'
        // properties with 'null' as value are part of the 'local.config.json' to give a hint which properties can be configured
        state.frontendOptions = merge(
            {
                navigation: {
                    medalStanding: true,
                    medallists: true,
                    worldRecords: false,
                    gamesRecords: false,
                    brokenRecords: false,
                    resultBooks: false,
                },
            } as ILRFrontendOptions,
            options,
        );

        state.language = qParams.get(LR_QUERY_NAMES.LANGUAGE) ?? DEFAULT_LANGUAGE_CODE;

        switch (state.config.mode) {
            case MODES.DEV:
                connect(PushServerClient, createDevPushClientOptions(state.config));
                break;
            case MODES.UAT:
                connect(PushServerClient, createUatPushClientOptions(state.config));
                break;
            default:
                connect(PushServerClient, createProdPushClientOptions(state.config));
        }
    }

    // </editor-fold>

    // <editor-fold desc="Watchers">
    watch(
        computed(() => channelNames.value?.standings),
        (newChan, oldChan) => {
            if (newChan) {
                substituteChannel(newChan, oldChan);
            }
        },
    );
    // </editor-fold>

    return {
        state,
        tournamentId,
        standingData,
        currentEventChannel,
        currentEventData,
        initStore,
        channelNames,
        connected: PushServerConnectionStatus,
    };
});

export const useLRStoreRefs = () => storeToRefs(useLRStore());
export const getLrStoreStateRefs = () => toRefs(useLRStore().state);
