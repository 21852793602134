export enum MODES {
    DEV = "development",
    UAT = "uat",
    PROD = "production",
}

export enum NAMESPACE_ENVIRONMENTS {
    DEV = "DEV",
    TEST = "TEST",
    PROD = "PROD",
}

export enum COMMON_CHANNEL_SUFFIXES {
    LIVE_RESULTS = "LR",
}
export enum LR_QUERY_NAMES {
    EVENT = "event",
    MODE = "mode",
    LANGUAGE = "lng",
    NAMESPACE = "namespace",
    HOST = "host",
    USEHTTPS = "usehttps",
}
