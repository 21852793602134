<template>
    <component :is="isString(href) ? 'a' : 'button'" :href="href" :class="{ [type]: true }" class="lr__button">
        <font-awesome-icon class="button-icon" v-if="icon" :icon="icon" />
        <div v-if="text || slots.default" class="button-text">
            <slot>{{ text }}</slot>
        </div>
    </component>
</template>

<script lang="ts" setup>
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { isString } from "lodash-es";
import { PropType } from "vue";

import { ButtonType } from "../../interfaces/component.interfaces";

defineProps({
    icon: Object as PropType<IconDefinition>,
    text: String,
    href: String,
    type: {
        type: String as PropType<ButtonType>,
        default: "btn-default",
    },
});

const slots = defineSlots<{
    default;
}>();
</script>

<style lang="scss">
.lr__button {
    height: 50px;
    padding: 12px;
    transition: color 0.3s ease, background-color 0.3s ease;
    border: none;
    font-family: var(--font-regular);
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    appearance: none;
    display: flex;
    align-items: center;
    text-decoration: none;

    @include max-mobile {
        padding: 10px;
        font-size: 14px;
        height: 40px;
    }

    &.btn-default {
        background-color: var(--font-color-2);
        color: var(--primary-1-contrast);

        &:hover {
            background-color: var(--primary-1-contrast);
            color: var(--font-color-2);
        }
    }

    &.btn-live {
        background-color: var(--live);
        color: var(--live-contrast);

        &:hover {
            background-color: var(--live-contrast);
            color: var(--live);
        }
    }

    &.btn-neutral {
        background-color: var(--font-color-1);
        color: var(--font-color-1-contrast);

        &:hover {
            background-color: var(--font-color-1-contrast);
            color: var(--font-color-1);
        }
    }

    &.btn-blank {
        background-color: var(--font-color-1-contrast);
        color: var(--font-color-1);

        &:hover {
            background-color: var(--font-color-1);
            color: var(--font-color-1-contrast);
        }
    }

    .button-icon {
        &:not(:last-child) {
            margin-right: 10px;
        }

        &:first-child {
            &:last-child {
                width: 50px;
                font-size: 24px;
            }
        }
    }
}
</style>
