<template>
    <footer class="lr__main-frame-footer">
        <div class="event-sponsors">
            <img alt="" class="sponsor-banner" :src="sponsorBanner" />
        </div>
        <LrOverlayButton text="Privacy Policy">
            <template #header>Riyadh2023 Privacy Policy </template>
            <template #overlay>
                <div class="lr__disclaimer-content">
                    <h2>Personal information we collect about you.</h2>
                    <p>We will collect the following personal information about you</p>

                    <p class="x--bold">Information you provide directly to us</p>

                    <ul>
                        <li>
                            When we communicate with you (including your name, email address, company name, postal
                            address, telephone number, any other personal information you provide and the content of our
                            communications).
                        </li>
                        <li>
                            When you register for a Riyadh2023 event (including your name, email address, postal
                            address, telephone number, work position, transaction details, credit card, passport
                            details).
                        </li>
                        <li>
                            When you attend a Riyadh2023 event (including your name, email address, work position,
                            conference and events booked/attended, areas of interest, industries of interest and images
                            and videos of you have taken at our events).
                        </li>
                        <li>
                            When you subscribe to Riyadh2023 email or postal mailing lists (including your name and
                            email, work position, company, or postal address, as appropriate).
                        </li>
                        <li>Other information that you choose to provide or send to us during our relationship.</li>
                    </ul>

                    <p class="x--bold">Data we derive through your interactions with us</p>
                    <ul>
                        <li>
                            General user information about your computer and your visits to our website (including your
                            IP address, location, browser, operating system, referral source, length of visit and the
                            pages you visit). This information can be facilitated by cookies.
                        </li>
                    </ul>

                    <h2>How we'll use your personal information</h2>

                    <p>
                        We’ll only use your personal information for our legitimate business purposes as set out in this
                        privacy notice.
                    </p>

                    <p class="x--bold">We use your data</p>

                    <ul>
                        <li>To administer our events, our websites and our business.</li>
                        <li>
                            To enable your use of our services, including registration and attendance at our events.
                        </li>
                        <li>To respond to your requests for information.</li>
                        <li>
                            To send you email or postal mail e.g. brochures or newsletters. When communication are sent
                            to for direct marketing purposes, you will have the right to object at any time to
                            processing of your information. Such communications will always include a clear, simple
                            option to refuse, or ‘opt-out’ of such future communications. You may also opt-out at any
                            time by contacting us (see below sections 10 and 11).
                        </li>
                        <li>
                            To publish the event delegate list to be posted on both the event mobile application and our
                            website. We will only disclose your photo, name, working title and organization on such
                            list. You may at any time opt-out from the event delegate list or object to some of your
                            information being disclosed on the event delegate list by contacting us (see below sections
                            10 and 11).
                        </li>
                        <li>To send invoices, reports, statements, payment reminders and collect payments from you.</li>
                        <li>To deal with any enquiries or complaints by or about you.</li>
                        <li>
                            To understand how you (and others) are using our services and to help us improve and develop
                            our services, including conducting internal analyses.
                        </li>

                        <li>To keep our events and systems secure and prevent fraud.</li>
                        <li>To send you other necessary information about our services and our relationship.</li>
                        <li>
                            To contact you about new Riyadh2023 services, offers, events or news where it is legitimate
                            for us to do so.
                        </li>
                        <li>
                            To otherwise manage our relationship with you or comply with our contractual obligations.
                        </li>
                    </ul>

                    <h2>Electronic badges</h2>

                    <ul>
                        <li>
                            We use badge scanning technology at our events for security reasons and to help improve your
                            experience and network effectively with our delegates and exhibitors.
                        </li>
                        <li>
                            Everyone attending our events requires an official, printed badge. The information displayed
                            will be provided by you during registration – either online or on site.
                        </li>
                        <li>
                            You’ll need to allow us to scan your badge to gain entry on to the exhibition and other
                            restricted areas.
                        </li>
                        <li>
                            Lead retrieval and all other badge scanning is optional. You do not have to let anyone else
                            scan your badge.
                        </li>
                        <li>
                            If you allow an exhibitor to scan your badge, you’re giving them a copy of some of your
                            registration information, just as if you were handing them a business card. The exhibitor
                            may use this information to contact you after the event or for other purposes that you agree
                            with them. The exhibitor’s use of your information in this situation is subject to your
                            direct relationship with the exhibitor and their own privacy policies.
                        </li>
                        <li>
                            We use a third-party supplier for badge fulfilment and lead scanning services. They’ll
                            process your information for us, and for the exhibitors that you authorize to access your
                            information when they scan your badge. The supplier will not have access to your information
                            post-event. We will enter into agreement with such supplier requiring it to comply with all
                            applicable laws and controlling how it process your information.
                        </li>
                    </ul>

                    <h2>Cookies policy</h2>

                    <ul>
                        <li>
                            We use cookies when you browse the Riyadh2023 website. Cookies are small files that allow a
                            website to recognise and track users.
                        </li>
                        <li>
                            Our cookies do not store personal information such as your name, address or registration
                            data; they simply hold the unique ID that, once you are signed in, is associated with this
                            information.
                        </li>
                        <li>
                            You can set your browser to reject cookies (see the ‘Help’ menu of your browser to find out
                            how to do this), but please bear in mind that if you do this, certain user-generated
                            features of our websites cannot be provided to you.
                        </li>
                    </ul>

                    <h2>When we share your personal information with others</h2>

                    <p>
                        We work collaboratively with others as part of providing our services and we may need to share
                        your information with them as follows:
                    </p>

                    <ul>
                        <li>
                            Delegates, exhibitors, partners and other event attendees: Our events facilitate all kinds
                            of interaction between those attending. This includes online facilities via our mobile app
                            to arrange meetings, to send messages to or network with others at our events.
                        </li>
                        <li>
                            Consultants, partners and service providers: We may need to share some personal information
                            of event attendees with our consultants and partners to deliver some of the show services
                            such as PR, and catering. This data may include your preferences (e.g. dietary requirements,
                            educational qualifications, expertise and interests etc.) as necessary to provide these
                            services to you.
                        </li>
                        <li>
                            Official show suppliers: We’ll share contact information (name, email, organisation, job
                            title) of exhibitor coordinators and partners registered for the show, with selected
                            official suppliers who provide key exhibiting services including electricity, stand
                            construction.
                        </li>
                        <li>
                            Travel and accommodation providers: We may share your data with our travel agent, hoteliers
                            and ground transfer partners to arrange your travel and accommodation at the show.
                        </li>
                        <li>
                            Other third-party suppliers: We also work with a number of other suppliers in our business
                            e.g. IT and software companies, hosting and communications providers, payroll providers etc.
                            to provide our services to you, who may need to process your data for us. All of our data
                            processors are contracted to protect your information and comply with applicable data
                            protection law. Third-party suppliers include: Salesforce CRM, Etouches, TapCrowd, Pardot,
                            and Google Analytics.
                        </li>
                        <li>
                            Riyadh2023 Stakeholders: We may share your attendee information across our group of
                            companies for the same purposes.
                        </li>
                        <li>
                            The Riyadh2023 website will have a real-time event delegate list disclosing the photo, name,
                            working title and organisation of each delegate attending our event. Such delegate list will
                            be available to anybody visiting Riyadh2023 website plus any delegate using the app.
                        </li>
                        <li>
                            Professional advisors and insurers: We may have to provide information to our advisors as
                            necessary for running our business.
                        </li>
                        <li>
                            Regulatory bodies and law enforcement: We may disclose personal information to a third-party
                            authority, such as a law enforcement agency, if required to do so by law.
                        </li>
                        <li>
                            We may, from time to time, expand, reduce or reorganize our business and this may involve
                            the sale and/or the transfer of control of all or part of our business to a third party. Any
                            personal data that you have provided will, where it is relevant to any part of our business
                            that is being transferred, be transferred along with that part and the new owner or newly
                            controlling party will, under the terms of this privacy notice, be permitted to use that
                            data only for the same purposes for which it was originally collected by us.
                        </li>
                    </ul>

                    <h2>How we store your information and keep it secure</h2>

                    <p>We’ll take care to ensure that your personal information is secure.</p>

                    <ul>
                        <li>
                            We follow good practice security protocols across our business to ensure that information is
                            protected.
                        </li>
                        <li>
                            We regularly review our information collection, storage and processing practices, including
                            physical security measures, to guard against unauthorized access to our systems.
                        </li>
                        <li>
                            We restrict access to personal information to Riyadh2023 staff, contractors and agents on a
                            need-to-know basis.
                        </li>
                        <li>
                            When we share information with our suppliers we enter into agreements with them requiring
                            them to comply with all applicable laws and controlling further distribution or disclosure
                            of the information to other parties.
                        </li>
                    </ul>

                    <h2>How long we will hold your information</h2>

                    <p>
                        We’ll try not to keep your personal information for longer than necessary for the permitted
                        purposes. We regularly review the data we hold and implement programs to delete unnecessary
                        information from our systems.
                    </p>

                    <p>
                        We may need to keep certain information for reasonable business or legal purposes (e.g. accounts
                        information, unsubscribe records, information needed to prevent identity theft, legal disputes
                        and misconduct) even if deletion has been requested.
                    </p>

                    <h2>Your data protection rights</h2>

                    <p class="x--bold">You have these rights in relation to your information</p>

                    <ul>
                        <li>
                            Access: You can obtain confirmation of whether we hold any information about you and, if so,
                            you can access your information and details of how we process it, if this does not adversely
                            affect the rights and freedoms of others.
                        </li>
                        <li>
                            Rectification: We will rectify any errors in the personal information we hold on request.
                        </li>
                        <li>
                            Erasure: You can ask us to delete your personal information from our systems in the
                            following situations:

                            <ul>
                                <li>
                                    The information is no longer necessary for the purpose for which it was collected.
                                </li>
                                <li>
                                    You withdraw your consent on which the information processing is based and where
                                    there is no other legal ground for the processing.
                                </li>
                                <li>
                                    You object to the information processing and there are no overriding legitimate
                                    grounds for the processing.
                                </li>
                                <li>The information has been unlawfully processed.</li>
                                <li>
                                    The information must be deleted for compliance with a legal obligation to which we
                                    are subject.
                                </li>
                            </ul>
                        </li>

                        <li>
                            Right to restrict processing: You have the right to restrict our information processing on
                            specified grounds.
                        </li>
                        <li>
                            Notification: Where you’ve asked us to rectify, delete or restrict processing of your
                            information, we’ll pass on your instructions to anyone we’ve shared your information with,
                            unless this proves impossible or involves disproportionate effort, in which case we’ll let
                            you know.
                        </li>
                        <li>
                            Data portability: You have the right in specific circumstances (where automated processing
                            is based on consent or contract) to receive your information in a structured, commonly used
                            and machine-readable format and have the right to transmit the information to another entity
                            without hindrance.
                        </li>
                        <li>
                            Right to object: In certain circumstances you have the right to object to our processing of
                            your information, including in relation to profiling, direct marketing or scientific or
                            historical research purposes.
                        </li>
                        <li>
                            Automated individual decision making: We don’t carry out this kind of decision making.
                            However, for your information, you have the right not to be subject to a decision based
                            solely on automated processing, including profiling, which produces legal effects concerning
                            you unless this is necessary for our contract, is authorised under applicable law or is
                            based on your explicit consent.
                        </li>
                    </ul>

                    <h2>How to exercise your rights</h2>

                    <p>
                        To exercise any of your rights contact us at
                        <a href="mailto:media@riyadh2034.sa"> media@riyadh2034.sa. </a>
                        We may need evidence of your identity and if so, the onus is on yourself to do so.
                    </p>
                    <p>
                        If you’re requesting access to your information, the first copy will be provided without charge,
                        but reasonable administration fees will be charged for additional or subsequent copies or we may
                        refuse repeated or excessive requests.
                    </p>
                    <p>
                        We’ll respond to your requests without undue delay and in any event within one month. That
                        period may be extended by two months, if your request is complex or we have a number of
                        requests. In this case we will let you know of the extended delay within one month following
                        your request.
                    </p>
                    <p>
                        Please note that if you delete or restrict information we hold about you, it may prevent you
                        from making full use of our services.
                    </p>

                    <p>
                        If you consider that the processing of your information infringes the legal regulations, you
                        have the right to lodge a complaint with the competent authority.
                    </p>

                    <h2>Contacting us about your privacy</h2>

                    <p>
                        You can contact us with any questions or comments at
                        <a href="mailto:media@riyadh2034.sa"> media@riyadh2034.sa. </a>
                    </p>

                    <h2>Changes to this privacy notice</h2>

                    <p>
                        We’ll regularly review and update this privacy notice and will ask for your consent before using
                        your personal information in a way that is significantly different. We however recommend that
                        you review this privacy notice regularly and every time you submit personal information to us to
                        keep informed of the content of the privacy notice.
                    </p>
                </div>
            </template>
        </LrOverlayButton>
        <!--        <ul class="disclaimer-links">-->
        <!--            <li>-->
        <!--                <a target="_blank" href="https://www.sportaccord.sport/privacy-policy/">Privacy Policy</a>-->
        <!--            </li>-->
        <!--        </ul>-->
    </footer>
</template>
<script setup lang="ts">
import { computed } from "vue";

import sponsorHorizontal from "../../assets/logos/sponsors/horizontal.png";
import sponsorVertical from "../../assets/logos/sponsors/vertical.png";
import { useCustomBreakpoints } from "../../composables/style-composables";
import LrOverlayButton from "../elements/LrOverlayButton.vue";

const { maxTablet } = useCustomBreakpoints();

const sponsorBanner = computed(() => (maxTablet.value ? sponsorVertical : sponsorHorizontal));
</script>

<style lang="scss">
.lr__main-frame-footer {
    display: flex;
    align-items: center;
    gap: 20px;
    max-width: $tv-min-width; // max page content width
    margin: 15px auto;
    padding: 10px 40px;
    min-height: 80px;

    .event-sponsors {
        display: flex;
        flex: 1;
        justify-content: space-around;
    }

    .sponsor-banner {
        max-width: 90%;
        height: auto;
    }

    .lr__button {
        margin-left: auto;
        background: var(--font-gradient-1);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: var(--font-bold);
        border-bottom: 1px solid transparent;
        text-transform: none;
    }

    @include max-desktop {
        flex-direction: column;
        .lr__button {
            margin-left: 0;
        }
    }

    @include max-tablet {
        .sponsor-banner {
            max-width: 40%;
        }
    }
}

.lr__disclaimer-content {
    .x--bold {
        font-family: var(--font-bold);
        font-size: 18px;
        color: var(--font-color-4);
        margin-top: 30px;
        text-decoration: underline;
    }

    p,
    li {
        margin: 0;
        margin-bottom: 15px;
        color: var(--font-color-1);
        font-size: 12px;
        line-height: 1.4;
        margin-left: 15px;

        a {
            color: var(--font-color-2);
            text-decoration: underline;
        }
    }

    li {
        list-style-type: disc;
        margin-bottom: 5px;

        margin-left: 30px;
    }

    h2,
    h3 {
        color: var(--font-color-1);
        counter-increment: chapter;
        margin-bottom: 15px;
        font-family: var(--font-condensed);

        &:not(:first-child) {
            margin-top: 45px;
        }
    }

    h2::before,
    h3::before {
        content: counter(chapter) ". ";
    }
}
</style>
