import { defineStore, storeToRefs } from "pinia";
import { computed, ComputedRef, Ref, toRef } from "vue";

import { createNameSpaceChannel } from "../constants/channel.names";
import { ChannelStatus } from "../enums/channel.enums";
import { IChannelMeta } from "../interfaces/channel";
import { VuePushClientDiffParser } from "../store/vue-json-diff.parser";
import { IPushClientOptions, PSClientManager } from "./pushserver-client.manager";

export enum CONNECTION_STATUS {
    CONNECTED = "connected",
    DISCONNECTED = "disconnected",
    FAILED = "failed",
}

export enum AUTHENTICATION_STATES {
    PENDING = "pending",
    AUTHORIZED = "authorized",
    FAILED = "failed",
}

interface IChannelStore {
    PS_Instance: any;
    PS_DefaultNameSpace: string;
    PS_ConnectionStatus: CONNECTION_STATUS;
    PS_AuhtorizedNamespaces: Map<string, { status: AUTHENTICATION_STATES; queue: (() => void)[] }>;
    Channels: { [x: string]: any };
}

export const useChannelStore = defineStore("CHANNEL_STORE", {
    state: (): IChannelStore => {
        return {
            PS_DefaultNameSpace: undefined,
            PS_Instance: undefined,
            PS_ConnectionStatus: CONNECTION_STATUS.DISCONNECTED,
            PS_AuhtorizedNamespaces: new Map(),
            Channels: {},
        };
    },
    actions: {
        connect(pushClientLib, clientOptions: IPushClientOptions) {
            const psInstance = PSClientManager.connect(pushClientLib, clientOptions);
            this.PS_Instance = psInstance;
            this.PS_DefaultNameSpace = clientOptions.defaultNamespace;

            // Register events
            PSClientManager.registerEvent("connected", () => {
                this.PS_ConnectionStatus = CONNECTION_STATUS.CONNECTED;
            });

            PSClientManager.registerEvent("disconnected", () => {
                this.PS_ConnectionStatus = CONNECTION_STATUS.DISCONNECTED;
            });

            PSClientManager.registerEvent("connectFailed", (err) => {
                this.PS_ConnectionStatus = CONNECTION_STATUS.FAILED;
                console.error(err);
            });

            PSClientManager.registerEvent("channelInit", (channelName, data, version) => {
                this.setChannelInit(channelName, data, version);
            });

            PSClientManager.registerEvent("channelUpdate", (channelName, patch, sync) => {
                const channel = this.Channels[channelName];
                VuePushClientDiffParser.applyPatch(channel.content, patch);
                channel.version = sync;
            });

            PSClientManager.registerEvent("channelNotInitialized", (channelName) => {
                this.Channels[channelName].status = ChannelStatus.NOT_INITIALIZED;
            });

            PSClientManager.registerEvent("uploadAuthorization", (success: boolean, namespace: string) => {
                const authentication = this.PS_AuhtorizedNamespaces.get(namespace);
                if (!authentication) {
                    this.PS_AuhtorizedNamespaces.set(namespace, { status: AUTHENTICATION_STATES.PENDING, queue: [] });
                }

                this.PS_AuhtorizedNamespaces.get(namespace).status = success
                    ? AUTHENTICATION_STATES.AUTHORIZED
                    : AUTHENTICATION_STATES.FAILED;
            });

            return psInstance;
        },
        setChannelInit(channelName: string, data: any, version: boolean) {
            const channel = this.Channels[channelName];
            channel.content = data;
            channel.status = ChannelStatus.ANSWERED;
            channel.version = version;
        },
        createChannel(channelName: string): IChannelMeta {
            this.Channels[channelName] = {
                version: -Infinity,
                status: ChannelStatus.PENDING,
                content: {},
                subscriber: 1, // add the subscriber, who was joining the channel
            };
            return this.Channels[channelName];
        },
        removeSubscriber(channelName: string) {
            const channelInstance = this.getChannelInstance(channelName);
            channelInstance.subscriber--;
            if (channelInstance.subscriber === 0) {
                this.Channels[channelName] = {
                    version: -Infinity,
                    status: ChannelStatus.REMOVED,
                    content: {},
                    subscriber: 0, // add the subscriber, who was joining the channel
                };
                this.PS_Instance.unsubscribe(channelName);
            }
        },

        //#region authorization and upload
        authorizeForUpload(namespace: string, user = "user1", password = "pw1") {
            this.PS_AuhtorizedNamespaces.set(namespace, { status: AUTHENTICATION_STATES.PENDING, queue: [] });
            this.PS_Instance.authorizeUpload(namespace, user, password)
                .then((response) => {
                    // promise is resolved, if the server was responding either by a successful authorisation or a failed attempt (true/false)
                    if (response) {
                        console.log(`Successfully authorized for ${namespace}`);
                        const namespaceAuth = this.PS_AuhtorizedNamespaces.get(namespace);
                        namespaceAuth.status = AUTHENTICATION_STATES.AUTHORIZED;
                        namespaceAuth.queue.forEach((uploadRequest) => {
                            uploadRequest();
                        });
                    } else {
                        console.log(`Authorization failed for ${namespace}`);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },

        isNamespaceAuthenticatedForUpload(namespace: string) {
            const authentication = this.PS_AuhtorizedNamespaces.get(namespace);
            return authentication && authentication.status === AUTHENTICATION_STATES.AUTHORIZED;
        },

        isNamespaceAuthorizationPending(namespace: string) {
            const authentication = this.PS_AuhtorizedNamespaces.get(namespace);
            return authentication && authentication.status === AUTHENTICATION_STATES.PENDING;
        },

        _requestChannelUpload(channelName: string, data: any, namespace: string) {
            return this.PS_Instance.upload(channelName, data, namespace)
                .then()
                .catch((err) => {
                    console.log(err);
                });
        },

        _requestBinaryUpload(fileObj: any, namespace: string, binaryChannelKey?: string) {
            return this.PS_Instance.uploadBinary(fileObj, namespace, binaryChannelKey)
                .then()
                .catch((err) => {
                    console.log(err);
                });
        },

        _requestBinaryDeletion(name: string, namespace: string, binaryChannelKey?: string) {
            return this.PS_Instance.deleteBinary(name, namespace, binaryChannelKey).catch((err) => {
                console.log(err);
            });
        },

        uploadChannel(channelName: string, data: any, namespace: string) {
            if (this.isNamespaceAuthenticatedForUpload(namespace)) {
                this._requestChannelUpload(channelName, data, namespace);
            } else if (this.isNamespaceAuthorizationPending(namespace)) {
                this.PS_AuhtorizedNamespaces.get(namespace).queue.push(() => {
                    this._requestChannelUpload(channelName, data, namespace);
                });
            } else {
                console.warn(
                    "Attempted to upload a channel for a not authorized namespace. Please use 'authorizeForUpload' method to authenticate first"
                );
            }
        },

        uploadBinary(fileObj: any, namespace: string, binaryChannelKey: string) {
            if (this.isNamespaceAuthenticatedForUpload(namespace)) {
                this._requestBinaryUpload(fileObj, namespace, binaryChannelKey);
            } else if (this.isNamespaceAuthorizationPending(namespace)) {
                this.PS_AuhtorizedNamespaces.get(namespace).queue.push(() => {
                    this._requestBinaryUpload(fileObj, namespace, binaryChannelKey);
                });
            } else {
                console.warn(
                    "Attempted to upload a binary for a not authorized namespace. Please use 'authorizeForUpload' method to authenticate first"
                );
            }
        },

        deleteBinary(name: string, namespace: string, binaryChannelKey: string) {
            if (this.isNamespaceAuthenticatedForUpload(namespace)) {
                this._requestBinaryDeletion(name, namespace, binaryChannelKey);
            } else if (this.isNamespaceAuthorizationPending(namespace)) {
                this.PS_AuhtorizedNamespaces.get(namespace).queue.push(() => {
                    this._requestBinaryDeletion(name, namespace, binaryChannelKey);
                });
            } else {
                console.warn(
                    "Attempted to delete a binary for a not authorized namespace. Please use 'authorizeForUpload' method to authenticate first"
                );
            }
        },

        //#endregion
    },
    getters: {
        getChannelInstance:
            (state) =>
            (channelName: string): IChannelMeta =>
                state.Channels[channelName],
    },
});

export const subscribeChannel = (channelName: string) => {
    const channelStore = useChannelStore();
    const cName = createNameSpaceChannel(channelStore.PS_DefaultNameSpace, channelName);
    const channel = channelStore.getChannelInstance(cName);
    if (!channel || channel.status === ChannelStatus.REMOVED) {
        channelStore.PS_Instance.subscribe(cName);
        return channelStore.createChannel(cName);
    } else {
        channel.subscriber++;
        return channel;
    }
};

export const unsubscribeChannel = (channelName: string) => {
    const channelStore = useChannelStore();

    const cName = createNameSpaceChannel(channelStore.PS_DefaultNameSpace, channelName);
    if (channelStore.getChannelInstance(cName)) {
        channelStore.removeSubscriber(cName);
    } else {
        console.warn(`Subscription removed for channel ${cName}, but there aren't any subscriber left.`);
    }
};

export const subscribeChannelContent = <TChannel>(channelName: string): Ref<TChannel> =>
    toRef(subscribeChannel(channelName), "content");

export const retrieveChannelContent = <TChannel>(channelName: Ref<string>): ComputedRef<TChannel> => {
    const channelStore = useChannelStore();

    return computed(() => {
        const cName = createNameSpaceChannel(channelStore.PS_DefaultNameSpace, channelName.value);
        return channelStore.getChannelInstance(cName)?.content ?? {};
    });
};

export const substituteChannel = (newChannel: string, oldChannel: string): IChannelMeta => {
    if (oldChannel) {
        unsubscribeChannel(oldChannel);
    }
    if (newChannel) {
        return subscribeChannel(newChannel);
    }
};
export const useChannelManagerRefs = () => storeToRefs(useChannelStore());
