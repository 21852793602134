<template>
    <component class="lr__status-batch" :is="tag">
        <div class="batch-content" v-if="batchText" :class="statusClass">{{ batchText }}</div>
    </component>
</template>

<script lang="ts" setup>
import { getStrByLng, ScheduleStatus } from "stuffjs";
import { computed, PropType } from "vue";

const props = defineProps({
    tag: {
        type: String,
        default: "div",
    },
    statusCode: {
        type: String as PropType<ScheduleStatus>,
    },
});

const batchText = computed(() => {
    switch (props.statusCode) {
        case ScheduleStatus.Rescheduled:
            return getStrByLng().LABEL_RESCHEDULED;
        case ScheduleStatus.Delayed:
            return getStrByLng().LABEL_DELAYED;
        case ScheduleStatus.ComingUp:
        case ScheduleStatus.GettingReady:
            return getStrByLng().LABEL_COMING_UP;
        case ScheduleStatus.Cancelled:
            return getStrByLng().LABEL_CANCELLED;
        case ScheduleStatus.Running:
        case ScheduleStatus.Break:
            return getStrByLng().LABEL_LIVE;
        case ScheduleStatus.Finished:
        case ScheduleStatus.Official:
            return getStrByLng().LABEL_FINISHED;
    }
    return "";
});

const statusClass = computed(() => {
    switch (props.statusCode) {
        case ScheduleStatus.Rescheduled:
            return "x--is-rescheduled";
        case ScheduleStatus.Delayed:
            return "x--is-delayed";
        case ScheduleStatus.ComingUp:
        case ScheduleStatus.GettingReady:
            return "x--is-coming-up";
        case ScheduleStatus.Cancelled:
            return "x--is-cancelled";
        case ScheduleStatus.Running:
        case ScheduleStatus.Break:
            return "x--is-live";
        case ScheduleStatus.Finished:
        case ScheduleStatus.Official:
            return "x--is-finished";
    }
    return undefined;
});
</script>

<style lang="scss">
.lr__status-batch {
    text-align: right;

    .batch-content {
        font-family: var(--font-condensed);
        font-size: 0.75rem;
        padding: 4px 6px;
        color: white;
        text-transform: uppercase;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        white-space: nowrap;

        @include max-mobile {
            font-size: 0.5rem;
            padding: 2px 4px;
        }

        &.x--is-live {
            border: 1px solid var(--live);
            color: var(--live-contrast);
            background-color: var(--live);
        }

        &.x--is-finished {
            border: 1px solid var(--secondary-1-darken);
            color: var(--secondary-1-darken);
        }

        &.x--is-coming-up {
            border: 1px solid var(--gray-7);
            color: var(--gray-7);
            background-color: var(--font-color-1-contrast);
        }
    }
}
</style>
