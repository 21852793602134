<template>
    <component class="vuc-flag" :class="[{ 'x--flag-only': !natCode }, `x--${size}`]" :is="tag">
        <VucImage v-if="targetUrl" class="nat-flag" :url="targetUrl"></VucImage>
        <span v-if="natCode" class="nat-code">{{ natCode }}</span>
    </component>
</template>

<script setup lang="ts">
import { Sizes } from "stuffjs";
import { computed, PropType } from "vue";

import VucImage from "./VucImage.vue";

const props = defineProps({
    flagCode: String,
    flagPublicPath: {
        type: String,
        default: "./flags/",
    },
    natCode: String,
    fileExtension: {
        type: String,
        default: "png",
    },
    sourceUrl: String,
    size: {
        type: String as PropType<Sizes>,
        default() {
            return Sizes.sm;
        },
    },
    tag: {
        type: String,
        default: "div",
    },
});

const natUrl = computed((): string => {
    const { flagCode, natCode, fileExtension } = props;
    if (flagCode || natCode) {
        return `${props.flagPublicPath}${flagCode ?? natCode}.${fileExtension}`;
    }
    return "";
});

const targetUrl = computed((): string => (props.sourceUrl ?? natUrl.value).toLowerCase());
</script>

<style lang="scss">
.vuc-flag {
    display: flex;
    align-items: center;

    .nat-code {
        padding-left: 0.5em;
    }

    .nat-flag {
        box-shadow: 0 0.05em 0.08em rgba(0, 0, 0, 0.3);
    }

    &.x--sm {
        .nat-code {
            font-size: 0.75rem;
            width: 2rem;
        }

        .nat-flag {
            width: 1.5em;
            height: 1em;
        }
    }

    &.x--md {
        .nat-code {
            font-size: 2rem;
        }

        .nat-flag {
            width: 3em;
            height: 2em;
        }
    }

    &.x--lg {
        .nat-code {
            font-size: 3rem;
        }

        .nat-flag {
            width: 4.5em;
            height: 3em;
        }
    }
}
</style>
