<template>
    <LrButton :icon="icon" :text="text" @click="open = !open">
        <slot></slot>
    </LrButton>
    <LrOverlay v-model:open="open">
        <template #header>
            <slot name="header">
                <font-awesome-icon v-if="icon" :icon="icon" />
                <span>{{ text }}</span>
            </slot>
        </template>
        <slot name="overlay"></slot>
    </LrOverlay>
</template>

<script lang="ts" setup>
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { PropType, ref } from "vue";

import LrOverlay from "../container/LrOverlay.vue";
import LrButton from "./LrButton.vue";

defineProps({
    icon: Object as PropType<IconDefinition>,
    text: String,
});

const open = ref(false);
</script>

<style lang="scss"></style>
