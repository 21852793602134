import { isNil } from "lodash-es";

export const useStickyLeftDirective = (application) => {
    application.directive("stick-left", {
        mounted(el: HTMLElement) {
            const table = el?.parentElement?.parentElement?.parentElement;
            if (!isNil(table)) {
                const tableOffset = table.getBoundingClientRect().x;
                const elementOffset = el.getBoundingClientRect().x;
                el.classList.add("x--stick-left");
                el.style.left = `${elementOffset - tableOffset}px`;
            }
        },
    });
};
