<template>
    <Channel :channel-name="channelName">
        <template #slot__result>
            <slot></slot>
        </template>
        <template #slot__not-initialized>
            <LrPlaceHolder :message="$getStrByLng().MESSAGE_NO_RESULTS_AVAILABLE"></LrPlaceHolder>
        </template>
    </Channel>
</template>

<script lang="ts" setup>
import { toRefs } from "vue";
import { Channel } from "vue-uc";

import LrPlaceHolder from "../../components/container/LrPlaceHolder.vue";
import { useChannelFromRscProp } from "../../composables/channel-composables";

const props = defineProps({
    rsc: {
        type: String,
        required: true,
    },
});

const { channelName } = useChannelFromRscProp(toRefs(props).rsc);
</script>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    inheritAttrs: false, // added because otherwise nested router views raise warnings 'Extraneous non-props attributes (rotation, apparatus) were passed'
});
</script>

<style scoped lang="scss"></style>
