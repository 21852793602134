<template>
    <th class="name--default table-cell--full-width">
        <slot>{{ $getStrByLng().HEADER_NAME }}</slot>
    </th>
</template>

<script setup lang="ts"></script>

<style>
th.name--default {
    text-align: left;
}
</style>
