import { sortBy } from "lodash-es";
import { ISport, SPORTS } from "stuffjs";

export const relevantSports: ISport[] = sortBy(
    [
        SPORTS.AIKIDO,
        SPORTS.ARMWRESTLING,
        SPORTS.BOXING,
        SPORTS.FENCING,
        SPORTS.JUJITSU,
        SPORTS.JUDO,
        SPORTS.KENDO,
        SPORTS.KICKBOXING,
        SPORTS.KARATE,
        SPORTS.MUAYTHAI,
        SPORTS.SAVATE,
        SPORTS.SAMBO,
        SPORTS.SUMO,
        SPORTS.TAEKWONDO,
        SPORTS.WRESTLING,
        SPORTS.WUSHU,
    ],
    (x) => x.name
);

export const routerContainerId = "router-content";
