import { isNil } from "lodash-es";
import {
    ChannelStatus,
    createNameSpaceChannel,
    retrieveChannelContent,
    subscribeChannel,
    subscribeChannelContent,
    substituteChannel,
    unsubscribeChannel,
    useChannelStore,
} from "stuffjs";
import { computed, ComputedRef, onUnmounted, Ref, watch } from "vue";

export const useChannelPlain = <TChannel>(channelName: string): Ref<TChannel> => {
    onUnmounted(() => {
        unsubscribeChannel(channelName);
    });
    return subscribeChannelContent<TChannel>(channelName);
};

export const useChannel = <TChannel>(channelName: Ref<string>): ComputedRef<TChannel> => {
    watch(channelName, substituteChannel, { immediate: true });
    onUnmounted(() => {
        unsubscribeChannel(channelName.value);
    });
    return retrieveChannelContent(channelName);
};

export const useChannels = <TChannel>(channelNames: Ref<string[]>, partial = false) => {
    const { PS_DefaultNameSpace, getChannelInstance } = useChannelStore();

    const relevantChannelNames = computed(() => channelNames.value?.filter((name) => !isNil(name)));

    const metaChannels = computed(
        () =>
            relevantChannelNames.value?.map((name) =>
                getChannelInstance(createNameSpaceChannel(PS_DefaultNameSpace, name))
            ) ?? []
    );

    const status = computed(() => {
        const statusList = metaChannels.value?.map(({ status }) => status);

        if (statusList.every((s) => s === ChannelStatus.ANSWERED)) {
            return ChannelStatus.ANSWERED;
        } else if (statusList.some((s) => s === ChannelStatus.ANSWERED)) {
            return ChannelStatus.PARTIAL;
        } else if (statusList.some((s) => s === ChannelStatus.PENDING)) {
            return ChannelStatus.PENDING;
        } else {
            return ChannelStatus.NOT_INITIALIZED;
        }
    });

    const channels = computed<TChannel[]>(() => {
        switch (status.value) {
            case ChannelStatus.ANSWERED:
                return metaChannels.value.map((channel) => channel?.content);
            case ChannelStatus.PARTIAL:
                if (partial) {
                    return metaChannels.value.map((channel) => channel?.content);
                } else {
                    return [];
                }
            default:
                return [];
        }
    });

    watch(
        relevantChannelNames,
        (currentNames, prevNames) => {
            const addedChannels = currentNames?.filter((name) => !prevNames?.includes(name)) ?? [];
            const removedChannels = prevNames?.filter((name) => !currentNames?.includes(name)) ?? [];

            addedChannels.forEach((name) => subscribeChannel(name));
            removedChannels.forEach((name) => unsubscribeChannel(name));
        },
        { immediate: true }
    );

    onUnmounted(() => {
        channelNames.value.forEach((name) => unsubscribeChannel(name));
    });

    return {
        status,
        channels,
    };
};
