import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

// wrapper around 'dayjs' date-library: https://day.js.org/en/
// check 'dayjs' formatting options here: https://day.js.org/docs/en/display/format
class _DateTimeManager {
    private globalOffset = 0;

    setGlobalOffset(offset = this.globalOffset): void {
        this.globalOffset = offset;
    }

    getGlobalOffset(): number {
        return this.globalOffset;
    }

    getTimestamp(): number {
        return dayjs().valueOf();
    }

    getISO8601(): string {
        return dayjs().toISOString();
    }

    getLocalDate(): dayjs.Dayjs {
        return dayjs();
    }

    getUTCDate(): dayjs.Dayjs {
        return this.getLocalDate().utc();
    }

    getUTCDateWithOffset(offset = this.globalOffset): dayjs.Dayjs {
        return this.getUTCDate().utcOffset(offset);
    }

    getCustomDate(date: dayjs.ConfigType, offset = this.globalOffset): dayjs.Dayjs {
        return date !== undefined ? dayjs(date).utc(true).utcOffset(offset) : undefined;
    }

    getCustomDateTimestamp(date: dayjs.ConfigType): number {
        return this.getCustomDate(date).valueOf();
    }

    getLogTimeFormat(): string {
        return dayjs().format("DD.MM.YYYY HH:mm:ss.SSS");
    }

    isSameLocalDate(date: dayjs.ConfigType, comparisonFormat = "D-M-YY"): boolean {
        const localDate = this.getLocalDate();
        const localOffset = localDate.utcOffset();
        const localDateString = localDate.format(comparisonFormat);

        return this.getCustomDate(date, localOffset).format(comparisonFormat) === localDateString;
    }

    isSameLocalDay(date: dayjs.ConfigType): boolean {
        return this.isSameLocalDate(date);
    }

    isSameLocalTime(date: dayjs.ConfigType): boolean {
        return this.isSameLocalDate(date, "H:m:s-D-M-YY");
    }
}

export const DateTimeManager = new _DateTimeManager();
