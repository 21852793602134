import { IRsc } from "../interfaces/channel.entities";
import { RecordAreaType } from "../interfaces/channel/commons";
import { isMeaningfulString } from "../utils/validation";

export const CHANNEL_PLACEHOLDER = {
    TOURNAMENT_ID: "#TOURNAMENT-ID#",
    RSC_EVENT: "#RSC-EVENT#",
    RSC_PHASE: "#RSC-PHASE#",
    RSC_UNIT: "#RSC-UNIT#",
    LOCATION_RSC_EVENT: "#RSC-EVENT#",
    LOCATION_RSC_PHASE: "#RSC-PHASE#",
    LOCATION_RSC_UNIT: "#RSC-UNIT#",
    LOCATION_ID: "#LOC#",
    SUB_LOCATION_ID: "#SUB-LOC#",
    RESULT_TYPE: "#RESULT-TYPE#",
    COMPETITOR_ID: "#COMPETITOR-ID#",
};

export const RESULT_TYPES_NAMES = {
    EVENT_TIMING: "EVENT_TIMING",
    TIMING: "TIMING",
    JUDGEMENT: "JUDGEMENT",
};

export const CHANNELS_NAMES = {
    CURRENT_EVENT: "CURRENTEVENT_JSON",
    EVENT_INFO: `${CHANNEL_PLACEHOLDER.TOURNAMENT_ID}_CURRENTEVENT_JSON`,
    SCHEDULE: `${CHANNEL_PLACEHOLDER.TOURNAMENT_ID}_SCHEDULE_JSON`,
    LIVE: `${CHANNEL_PLACEHOLDER.TOURNAMENT_ID}_LIVE_JSON`,
    EVENT_SUMMARY: `${CHANNEL_PLACEHOLDER.TOURNAMENT_ID}_EVENTSUMMARY-${CHANNEL_PLACEHOLDER.RESULT_TYPE}_${CHANNEL_PLACEHOLDER.RSC_EVENT}_JSON`,
    PHASE_SUMMARY: `${CHANNEL_PLACEHOLDER.TOURNAMENT_ID}_PHASESUMMARY-${CHANNEL_PLACEHOLDER.RESULT_TYPE}_${CHANNEL_PLACEHOLDER.RSC_PHASE}_JSON`,
    EVENT_RESULT: `${CHANNEL_PLACEHOLDER.TOURNAMENT_ID}_${CHANNEL_PLACEHOLDER.RESULT_TYPE}_${CHANNEL_PLACEHOLDER.RSC_EVENT}_JSON`,
    UNIT_RESULT: `${CHANNEL_PLACEHOLDER.TOURNAMENT_ID}_${CHANNEL_PLACEHOLDER.RESULT_TYPE}_${CHANNEL_PLACEHOLDER.RSC_UNIT}_JSON`,
    BINARIES: "BINARIES",
    EVENT_BINARIES: `BINARIES_${CHANNEL_PLACEHOLDER.TOURNAMENT_ID}`,
    COMPETITOR_EVENT_STATS: `${CHANNEL_PLACEHOLDER.TOURNAMENT_ID}_STATISTICS_${CHANNEL_PLACEHOLDER.RSC_EVENT}_COMPETITOR_${CHANNEL_PLACEHOLDER.COMPETITOR_ID}_JSON`,
};
CHANNELS_NAMES[RESULT_TYPES_NAMES.TIMING] = CHANNELS_NAMES.UNIT_RESULT.replace(
    CHANNEL_PLACEHOLDER.RESULT_TYPE,
    RESULT_TYPES_NAMES.TIMING,
);
CHANNELS_NAMES[RESULT_TYPES_NAMES.EVENT_TIMING] = CHANNELS_NAMES.EVENT_RESULT.replace(
    CHANNEL_PLACEHOLDER.RESULT_TYPE,
    RESULT_TYPES_NAMES.TIMING,
);

export const createFullChannelName = (namespace: string, channel: string): string => `${namespace}|${channel}`;

export const createNameSpaceChannel = (namespace: string, channelName: string): string => {
    if (isMeaningfulString(channelName) && channelName.includes("|")) {
        return channelName;
    } else {
        return createFullChannelName(namespace, channelName);
    }
};

const searchReplacements = (nameSpace: string, channelName: string, mapReplacements): string => {
    const regEx = new RegExp(Object.keys(mapReplacements).join("|"), "gi");
    return createFullChannelName(
        nameSpace,
        channelName.replace(regEx, (matched) => mapReplacements[matched]),
    );
};

export const buildChannelName = (nameSpace, channelName, tournamentId, rscObj: IRsc = {}, locationInstance) => {
    let location;
    let subLocation;

    if (locationInstance) {
        location = locationInstance.Location;
        subLocation = locationInstance.SubLocation;
    }

    const mapReplacements = {
        [CHANNEL_PLACEHOLDER.TOURNAMENT_ID]: tournamentId,
        [CHANNEL_PLACEHOLDER.RSC_EVENT]: rscObj.ValueEvent,
        [CHANNEL_PLACEHOLDER.RSC_PHASE]: rscObj.ValuePhase,
        [CHANNEL_PLACEHOLDER.RSC_UNIT]: rscObj.Value,
        [CHANNEL_PLACEHOLDER.LOCATION_ID]: location,
        [CHANNEL_PLACEHOLDER.SUB_LOCATION_ID]: subLocation,
    };

    return searchReplacements(nameSpace, channelName, mapReplacements);
};

export const buildTournamentChannel = (nameSpace, channelName, tournamentId) => {
    const mapReplacements = {
        [CHANNEL_PLACEHOLDER.TOURNAMENT_ID]: tournamentId,
    };

    return searchReplacements(nameSpace, channelName, mapReplacements);
};

export const getCurrentChannel = (eventId) => `${eventId}_CURRENT_JSON`;

export const getEventSummaryChannel = (rsc, eventId, type = RESULT_TYPES_NAMES.TIMING) =>
    `${eventId}_EVENTSUMMARY-${type}_${rsc}_JSON`;

export const getPhaseSummaryChannel = (rsc, eventId, type = RESULT_TYPES_NAMES.TIMING) =>
    `${eventId}_PHASESUMMARY-${type}_${rsc}_JSON`;

export const getTimingChannel = (tournamentId, rsc: string) => `${tournamentId}_TIMING_${rsc}_JSON`;

export const getCompRecordsChannel = (tournamentId, suffix: string) => `${tournamentId}_COMPRECORDS_${suffix}_JSON`;

export const getLiveChannel = (tournamentId) =>
    CHANNELS_NAMES.LIVE.replace(CHANNEL_PLACEHOLDER.TOURNAMENT_ID, tournamentId);

export const getMedalTableChannel = (tournamentId) => `${tournamentId}_REPORT_STANDING_MEDALTABLE_JSON`;
export const getMedallistsChannel = (tournamentId) => `${tournamentId}_MEDALLISTBYEVENT_JSON`;

export const getScheduleChannel = (tournamentId, nameSuffix?) =>
    `${tournamentId}_SCHEDULE_${nameSuffix ? `${nameSuffix}_` : ""}JSON`;

export const getEventInfoChannel = (tournamentId) =>
    CHANNELS_NAMES.EVENT_INFO.replace(CHANNEL_PLACEHOLDER.TOURNAMENT_ID, tournamentId);

export const getCurrentEventChannel = () => CHANNELS_NAMES.CURRENT_EVENT;

export const getCompetitorEventStatisticsChannel = (tournamentId, rscEvent, competitorId) =>
    `${tournamentId}_STATISTICS_${rscEvent}_COMPETITOR_${competitorId}_JSON`;

export const getBinariesChannel = (suffix = "") => {
    const optSuffix = suffix.length > 0 ? `_${suffix}` : "";
    return `${CHANNELS_NAMES.BINARIES}${optSuffix}`;
};

export const getEventBinariesChannel = (tournamentId: string) => {
    if (tournamentId) {
        return `BINARIES_${tournamentId}`;
    }
    return undefined;
};

export const getCompStrucChannel = (tournamentId) => `${tournamentId}_COMPSTRUCT_JSON`;

export const getFinalRankingChannel = (tournamentId, eventRsc) => {
    if (tournamentId && eventRsc) {
        return `${tournamentId}_FINALRANKING_${eventRsc}_JSON`;
    }
    return undefined;
};

export const getCompDetailsChannel = (tournamentId, rsc) => `${tournamentId}_COMPDETAILS_${rsc}_JSON`;

export const getStandingDataChannel = (tournamentId) => `${tournamentId}_STANDINGDATA_JSON`;

export const getEntriesChannel = (tournamentId) => `${tournamentId}_ENTRIES_JSON`;

export const getCombatChannel = (tournamentId, rsc) => `${tournamentId}_COMBAT_${rsc}_JSON`;

export const getGameChannel = (tournamentId, rsc) => `${tournamentId}_GAME_${rsc}_JSON`;

export const getStandingsChannel = (tournamentId, rsc) => `${tournamentId}_STANDINGS_${rsc}_JSON`;

export const getGameActionChannel = (tournamentId, rsc, period) => `${tournamentId}_GAMEACTIONS_${rsc}_${period}_JSON`;

export const getRacquetChannel = (tournamentId, rsc) => `${tournamentId}_RACQUET_${rsc}_JSON`;

export const getJudgementChannel = (tournamentId, rsc) => `${tournamentId}_JUDGEMENT_${rsc}_JSON`;

export const getIntermediateChannel = (tournamentId: string, rsc: string, int: string) =>
    `${tournamentId}_INTERMEDIATE_${rsc}_${int}_JSON`;

export const getRecordsChannel = (tournamentId: string, recordType: RecordAreaType) =>
    `${tournamentId}_RECORDS_${recordType}_JSON`;
export const getRecordSetClassesChannel = (tournamentId: string) => `${tournamentId}_RECORDSET_CLASSES_JSON`;
