<template>
    <ul class="lr__tab-selector">
        <li
            v-if="optionsList?.withAllItem"
            :class="{
                [CssClassModifiers.IsSelected]: selectedItem === undefined,
            }"
            class="all"
            @click="selectionChange(undefined)"
        >
            {{ optionsList.allItemName || $getStrByLng().LABEL_ALL }}
        </li>
        <li
            :class="{
                [CssClassModifiers.IsSelected]: selectedItem?.value === item.value,
                [CssClassModifiers.IsLive]: item.isHighlighted,
                [CssClassModifiers.IsDisabled]: item.isDisabled,
            }"
            @click="selectionChange(item)"
            v-for="item in optionsList?.selectList"
            :key="item.value"
        >
            {{ item.name }}
        </li>
    </ul>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { CssClassModifiers } from "../../enums/dynamic-modifiers";
import { ISelectList, ISelectListItem } from "../../interfaces/component.interfaces";

export default defineComponent({
    name: "LrPlainTabSelector",
    props: {
        optionsList: Object as PropType<ISelectList>,
        selectedValue: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        selectedItem() {
            return this.optionsList.selectList?.find((i) => i.value === this.selectedValue);
        },
    },
    emits: ["update:selectedValue"],
    setup() {
        return {
            CssClassModifiers,
        };
    },
    methods: {
        selectionChange(selectedItem: ISelectListItem) {
            this.$emit("update:selectedValue", selectedItem?.value);
        },
    },
});
</script>

<style lang="scss">
.lr__tab-selector {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px;

    li {
        transition: all 0.5s ease;
        font-family: var(--font-medium);
        font-size: var(--size-fluid-3);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-top: 3px solid var(--secondary-1);
        background-color: white;
        flex: 0 0 110px;
        margin-bottom: 10px;

        &.all {
            font-size: 20px;
            text-transform: uppercase;
            flex: 0 0 110px;
        }

        &:not(:last-child) {
            margin-right: 2px;
        }

        &.xg--is-disabled {
            color: var(--gray-4);
            cursor: default;
            pointer-events: none;
        }

        &.xg--is-live {
            color: var(--live);
            border-color: var(--live);
        }

        &.xg--is-selected {
            cursor: default;
            background-color: var(--secondary-1);
            color: white;

            &.xg--is-live {
                color: white;
                background-color: var(--live);
            }
        }

        &:hover {
            &:not(.xg--is-disabled, .xg--is-live, .xg--is-selected) {
                cursor: pointer;
                background-color: var(--secondary-1);
                color: white;
            }

            &.xg--is-live {
                cursor: pointer;
                color: white;
                background-color: var(--live);
            }

            &.xg--is-selected {
                cursor: default;
            }
        }
    }

    &.period-filter {
        li {
            font-size: var(--size-fluid-2);
            flex: 1;
            max-width: 260px;

            @include max-mobile {
                flex: 0 0 calc(50% - 2px);
            }
        }
    }

    &.medal-navigation,
    &.records-navigation {
        li {
            font-size: var(--size-fluid-2);
            flex: 1;
            max-width: 350px;
        }
    }

    &.rotation-filter {
        li {
            font-size: var(--size-fluid-2);
            flex: 1;
            max-width: 185px;
        }

        @include max-mobile {
            margin-bottom: 0;

            li {
                flex: calc(33% - 2px);
            }
        }
    }

    &.qualification-navigation {
        margin-bottom: 5px;

        li {
            font-size: var(--size-fluid-2);
            flex: 1;
            max-width: 372px;
        }

        @include max-mobile {
            margin-bottom: 0;

            li {
                flex: calc(50% - 2px);
            }
        }
    }
}
</style>
