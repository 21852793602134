import { handleWarn } from "stuffjs";
import { useRouter } from "vue-router";

import { DefaultRoutes } from "../enums/routes";
import { useLRStoreRefs } from "../store/lr-store";

export const useCommonRoutes = () => {
    const router = useRouter();

    const routeToResult = (rsc: string) => {
        router
            .push({
                name: DefaultRoutes.Result,
                params: { rsc },
            })
            .catch(handleWarn);
    };

    const hrefToResult = (rsc: string) =>
        router.resolve({
            name: DefaultRoutes.Result,
            params: { rsc },
        })?.href;

    const routeToHome = () => {
        router.push({ name: DefaultRoutes.Schedule }).catch(handleWarn);
    };

    const hrefToHome = () =>
        router.resolve({
            name: DefaultRoutes.Schedule,
        })?.href;

    const hrefToSportSelection = () =>
        // const { state } = useLRStoreRefs();
        "/index.html";
    // return state.value.config.sportSelectionURL;
    const hrefToEventPage = () => {
        const { state } = useLRStoreRefs();
        return state.value.config.eventURL;
    };

    const routeToSponsor = () => {
        const { state } = useLRStoreRefs();
        window.open(state.value.config.sponsorURL, "_self");
    };

    const routeToMedal = () => {
        router.push({ name: DefaultRoutes.Medals }).catch(handleWarn);
    };

    const hrefToMedal = () =>
        router.resolve({
            name: DefaultRoutes.Medals,
        })?.href;

    const hrefToRanking = () =>
        router.resolve({
            name: DefaultRoutes.FinalRanking,
        })?.href;

    const hrefToRecords = () =>
        router.resolve({
            name: DefaultRoutes.Records,
        })?.href;

    const hrefToPoolStandings = () =>
        router.resolve({
            name: DefaultRoutes.PoolStandings,
        })?.href;

    const hrefToResultBooks = () =>
        router.resolve({
            name: DefaultRoutes.ResultBooks,
        })?.href;

    return {
        routeToResult,
        hrefToResult,
        routeToHome,
        hrefToHome,
        hrefToPoolStandings,
        hrefToEventPage,
        hrefToSportSelection,
        routeToSponsor,
        routeToMedal,
        hrefToMedal,
        hrefToRanking,
        hrefToRecords,
        hrefToResultBooks,
    };
};
