<template>
    <div class="entry-page-frame">
        <header class="entry-page-header">
            <div class="container">
                <a class="event-logo-link" :href="hrefToEventPage()">
                    <img class="event" :src="eventLogo" />
                </a>

                <!--                <a class="logos-mid">-->
                <!--                    <img @click="routeToSponsor()" class="sponsor" :src="sponsorURL" />-->
                <!--                </a>-->
            </div>
        </header>
        <div ref="hostElement" class="entry-page">
            <Channel :channel-name="currentEventChannelName" @update:channel="currentChannel = $event">
                <template #slot__result>
                    <div class="banner-image"></div>
                    <div class="head-content">
                        <div class="content-container">
                            <h1 class="sports-title">Sports</h1>
                            <div class="sport-filter">
                                <LrPlainTabSelector
                                    :options-list="daySelectListModel"
                                    class="day-filter"
                                    v-model:selected-value="dayFilter"
                                />

                                <div class="sport-selection">
                                    <VucTransitionList tag="ul" transition="t--list-slide" class="sport-list">
                                        <SportElement
                                            tag="li"
                                            v-for="sport in filteredSports"
                                            :key="sport.sportCode"
                                            :sport="sport"
                                        ></SportElement>
                                    </VucTransitionList>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="detailed-content">
                        <div class="content-container">
                            <TotalMedalStanding class="total-medal-standing"></TotalMedalStanding>
                        </div>
                    </div>
                </template>
            </Channel>
        </div>

        <LrDisclaimer></LrDisclaimer>
    </div>
</template>

<script setup lang="ts">
import { useResizeObserver } from "@vueuse/core";
import { ISelectList, LrDisclaimer, LrPlainTabSelector, relevantSports, useCommonRoutes } from "lr-core";
import eventLogo from "lr-core/src/assets/logos/combat_games_logo.png";
import {
    addLeadingZero,
    DateTimeManager,
    getCurrentEventChannel,
    IGlobalCurrentEventChannel,
    MeetingStatus,
    ScheduleStatus,
} from "stuffjs";
import { computed, ref } from "vue";
import { Channel, VucTransitionList } from "vue-uc";

import SportElement from "./components/SportElement.vue";
import TotalMedalStanding from "./components/TotalMedalStanding.vue";
import { useEntryPageRefs } from "./store/entry-page.store";

const { hrefToEventPage, routeToSponsor } = useCommonRoutes();

const hostElement = ref(null);
const dayFilter = ref();

const { localConf } = useEntryPageRefs();

const currentEventChannelName = getCurrentEventChannel();
const currentChannel = ref<IGlobalCurrentEventChannel>();

// Needed for iframe integration
useResizeObserver(hostElement, (entries) => {
    const entry = entries[0];
    const { height } = entry.contentRect;
    // use "*" as targetorigin for testing purposes
    parent.postMessage(`${height}px`, "*");
    // parent.postMessage(`${height}px`, "https://www.longinestiming.com");
});

const indexedDays = computed(
    () =>
        currentChannel.value?.ListDay?.map((x, index) => {
            return { dayIndex: addLeadingZero(index + 1), dayValue: x };
        }) // YYYY-MM-DD
);
const mapMeetingStatusToScheduleStatus = (meetingStatus: MeetingStatus): ScheduleStatus => {
    switch (meetingStatus) {
        case MeetingStatus.Break:
            // As we dont want to display break as live in entry-page, we dont map break to break.
            return ScheduleStatus.Scheduled;
        // return ScheduleStatus.Break;
        case MeetingStatus.ComingUp:
            return ScheduleStatus.ComingUp;
        case MeetingStatus.Running:
            return ScheduleStatus.Running;
        case MeetingStatus.Finished:
            return ScheduleStatus.Finished;
    }
    return undefined;
};

const sportsList = computed(() => Object.entries(currentChannel.value?.Sports ?? []));

const relevantSportsWithLiveData = computed(() => {
    if (sportsList.value) {
        return relevantSports.map((relevantSport) => {
            const foundEntry = sportsList.value.find(
                ([sportCode]) => sportCode.toLowerCase() === relevantSport.sportCode
            );
            if (foundEntry) {
                const [, sportOfChannel] = foundEntry;
                return {
                    ...relevantSport,
                    status: mapMeetingStatusToScheduleStatus(sportOfChannel.Status),
                    hasCompetitionsToday: sportOfChannel.HasCompetitionsToday,
                    listDay: sportOfChannel.ListDay,
                };
            } else {
                return {
                    ...relevantSport,
                    listDay: [],
                };
            }
        });
    }
    return [];
});

const filteredSports = computed(() =>
    dayFilter.value
        ? relevantSportsWithLiveData.value.filter((sport) => sport.listDay?.some((day) => day === dayFilter.value))
        : relevantSportsWithLiveData.value
);

const daySelectListModel = computed((): ISelectList => {
    return {
        withAllItem: true,
        allItemName: "All Days",
        selectList: indexedDays.value?.map((date) => {
            return {
                value: date.dayValue,
                name: DateTimeManager.getCustomDate(date.dayValue).format("D"),
                isHighlighted: DateTimeManager.isSameLocalDate(date.dayValue),
            };
        }),
    };
});
</script>

<style lang="scss">
.entry-page-header {
    position: relative;
    background-color: white;
    z-index: var(--layer-1);

    .container {
        max-width: 1300px;
        margin: auto;
        position: relative;
        padding-top: 16px;
        padding-bottom: 16px;

        &:not(:empty) {
            @include max-desktop {
                padding-left: 10px;
                padding-right: 10px;
                display: flex;
                justify-content: center;
            }
        }

        .event {
            height: 80px;
            cursor: pointer;
        }
    }
}

.entry-page {
    .banner-image {
        position: absolute;
        top: 140px;
        right: 30px;
        width: 500px;
        height: 100%;
        background-image: url("./assets/backgrounds/banner-img.svg");
        background-repeat: no-repeat;
        background-size: contain;
    }

    .head-content {
        padding-top: 30px;
        padding-bottom: 60px;
        background: linear-gradient(18deg, var(--bg-5) 47.22%, var(--bg-6) 73.59%);

        &:not(:empty) {
            @include max-desktop {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .content-container {
            max-width: 1300px;
            margin: auto;
            position: relative;
        }

        .sports-title {
            font-family: var(--font-bold);
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: var(--font-color-2);
        }

        .sport-filter {
            .lr__tab-selector {
                @include max-mobile {
                    gap: 2px;
                }

                li {
                    border-top-color: var(--primary-2);

                    @include max-tablet {
                        flex: 0 0 45px;
                        padding: 5px;

                        &.all {
                            font-size: 16px;
                            text-align: center;
                        }
                    }

                    @include max-mobile {
                        flex: 0 0 calc(25% - 2px);
                        padding: 5px 0;
                        font-size: 16px;
                        margin-right: 0;
                    }

                    &.xg--is-disabled {
                        color: var(--gray-4);
                    }

                    &.xg--is-live {
                        color: var(--live);
                        border-color: var(--live);
                    }

                    &.xg--is-selected {
                        background-color: var(--secondary-1);
                        color: white;

                        &.xg--is-live {
                            color: white;
                            background-color: var(--live);
                        }
                    }

                    &:hover {
                        &:not(.xg--is-disabled, .xg--is-live, .xg--is-selected) {
                            background-color: var(--primary-2);
                            color: white;
                        }

                        &.xg--is-live {
                            color: white;
                            background-color: var(--live);
                        }
                    }

                    &.xg--is-selected {
                        background-color: var(--primary-2);
                    }
                }
            }
        }

        .sport-selection .sport-list {
            display: grid;
            gap: 10px;
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));

            @include max-tablet {
                grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
            }

            @include max-mobile {
                grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
            }
        }
    }

    .detailed-content {
        min-height: 1000px;
        padding-top: 30px;
        padding-bottom: 60px;
        background: linear-gradient(180deg, var(--bg-3), var(--bg-4));
        background-color: var(--bg-4);
        background-repeat: no-repeat;
        background-size: 100% 1200px;

        &:not(:empty) {
            @include max-desktop {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .content-container {
            max-width: 1300px;
            margin: auto;
            position: relative;
        }
    }
}
</style>
