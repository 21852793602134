<template>
    <component class="vuc-name" :is="tag">
        <span class="last-name">{{ name }}</span>
        <span class="first-name" v-if="firstName">{{ firstName }}</span>
    </component>
</template>
<script>
export default {
    name: "VucName",
    props: {
        tag: {
            type: String,
            default: "div",
        },
        firstName: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
    },
};
</script>

<style lang="scss">
.vuc-name {
    .first-name {
        &:empty {
            display: none;
        }

        &:not(:first-child) {
            &:before {
                content: " ";
            }
        }
    }
}
</style>
