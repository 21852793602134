import { computed, Ref } from "vue";

import { IStandingDataChannel } from "../interfaces/channel/standing-data";

export const useStandingData = (standingData: Ref<IStandingDataChannel>) => {
    const getStatusName = (statusCode): string => {
        if (statusCode) {
            return standingData.value?.Statusses?.[statusCode]?.Name;
        }
    };
    const getStatusCode = (statusCode): string => {
        if (statusCode) {
            return standingData.value?.Statusses?.[statusCode]?.Code;
        }
    };

    const getIrmCode = (irmCode: string) => {
        if (irmCode) {
            return standingData.value?.Statusses?.[irmCode]?.Code;
        }
    };

    const getIrmName = (irmCode): string => {
        if (irmCode) {
            return standingData.value?.Statusses?.[irmCode]?.Name;
        }
    };

    const getIrmCodeOrName = (irmCode) => getIrmName(irmCode) || getIrmCode(irmCode);

    const getRecordDescription = (recordCode: string): string =>
        standingData.value?.RecordClasses?.[recordCode]?.Description;

    const getLocationTitle = (locationCode): string => {
        if (locationCode) {
            return standingData.value?.Locations?.[locationCode]?.Title;
        }
    };

    const qualificationCodes = computed(() =>
        Object.values(standingData.value?.Statusses ?? {}).filter((status) => status.Category === "QualificationCode")
    );

    const getQualificationInfo = (qCode) => qualificationCodes.value.find((qInfo) => qInfo.Code === qCode);

    return {
        getStatusName,
        getStatusCode,
        getQualificationInfo,
        getIrmCode,
        getIrmName,
        getIrmCodeOrName,
        getLocationTitle,
        qualificationCodes,
        getRecordDescription,
    };
};
