<template>
    <component :is="tag">
        <div class="sport-element">
            <a draggable="false" :href="getSportUrlLink(sport.sportCode)" target="_parent">
                <img :src="getImageUrl(sport.sportCode)" :alt="sport.name" />
                <LrStatusBatch :status-code="sport.status"></LrStatusBatch>
                <span class="title">{{ sport.name }}</span>
            </a>
        </div>
    </component>
</template>

<script lang="ts" setup>
import { getLrSportURL, LrStatusBatch } from "lr-core";
import { ScheduleStatus, SportTypes } from "stuffjs";
import { PropType } from "vue";

import { useEntryPageRefs } from "../store/entry-page.store";

interface ISportElement {
    sportCode?: string;
    hasCompetitionsToday?: boolean;
    sportType?: SportTypes;
    name?: string;
    status?: ScheduleStatus;
}

defineProps({
    tag: {
        type: String,
        default: "div",
    },
    sport: {
        type: Object as PropType<ISportElement>,
        default(): ISportElement {
            return {};
        },
    },
});
const getImageUrl = (sportCode: string, imgFormat = "jpg") =>
    new URL(`../assets/${sportCode}.${imgFormat}`, import.meta.url).href;
// const { localConf } = useEntryPageRefs();

// const getSportUrlLink = (sportCode) =>
//     getLrSportURL(sportCode, localConf.value.mode, localConf.value.liveResultsDomain);

const getSportUrlLink = (sportCode) => `sports/${sportCode}/index.html`;

</script>

<style lang="scss">
.sport-element {
    position: relative;
    display: flex;

    @include max-mobile {
        height: 150px;
        a {
            overflow: hidden;
        }
    }

    a {
        display: block;
        width: 100%;
    }

    a::before {
        content: "";
        position: absolute;
        z-index: var(--layer-1);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: top center;
        background-size: auto 100%;
        background-color: rgba(0, 0, 0, 0.5);
        border: 1px solid var(--bg-6);
        transition: all 0.5s;
    }

    a:hover {
        &:before {
            background-color: rgba(0, 0, 0, 0);
        }

        img {
            position: relative;
        }

        .title {
            background-color: var(--primary-2);
        }
    }

    img {
        pointer-events: none;
        inline-size: 100%;
        aspect-ratio: var(--ratio-widescreen);
        object-fit: cover;
        transition: all 0.3s var(--ease-in-1);
        height: 100%;

        &[alt] {
            background-color: var(--primary-1);
        }
    }

    .title {
        font-size: var(--size-fluid-2);
        color: white;
        font-family: var(--font-condensed);
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        text-transform: uppercase;
        z-index: var(--layer-1);
        background-color: black;
        padding: 6px 8px;
        line-height: 1;
        border: 1px solid var(--bg-6);
        border-top: none;
        transition: all 0.5s;
    }

    .lr__status-batch {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: var(--layer-2);
    }
}
</style>
