import { mapValues, orderBy } from "lodash-es";
import { computed, Ref } from "vue";
import { useChannel } from "vue-uc";

import { IMedalCompetitor, IMedallistsChannel, IMedalTableChannel } from "../interfaces/channel/medals";
import { addLeadingZero, getCombinedValue } from "../utils/stuff.utils";

export const useMedalTableChannelData = (rawData: Ref<IMedalTableChannel>) => {
    const sortedCompetitors: Ref<IMedalCompetitor[]> = computed(() =>
        orderBy(Object.values(rawData.value.Competitors ?? {}), (competitor) => competitor.ListIndex)
    );

    const hasGenders = computed(() => {
        return {
            hasMixed: rawData.value.Stats?.HasMixed === "True",
            hasMen: rawData.value.Stats?.HasMen === "True",
            hasWomen: rawData.value.Stats?.HasWomen === "True",
        };
    });
    return { rawData, sortedCompetitors, hasGenders };
};

export const useMedalTableChannel = (channelName: Ref<string>) => {
    const rawData = useChannel<IMedalTableChannel>(channelName);
    return useMedalTableChannelData(rawData);
};

export const useMedallistsChannelData = (rawData: Ref<IMedallistsChannel>) => {
    const sortedEvents = computed(() =>
        orderBy(rawData.value.Events ? Object.values(rawData.value.Events) : [], (event) => {
            event.ListIndex;
        })
    );

    const sortedEventsWithFullNameCompetitors = computed(() =>
        sortedEvents.value.map((x) => {
            return {
                ...x,
                Competitors: mapValues(x.Competitors, (comp) => {
                    return { ...comp, FullName: getCombinedValue(comp.Name, comp.FirstName) };
                }),
            };
        })
    );

    const uniqueAvailableGenders = computed(() => [...new Set(sortedEvents.value?.map((event) => event.Rsc.Gender))]);

    const indexedDays = computed(() =>
        rawData.value?.MedallistsDates?.map((x, index) => {
            return { dayIndex: addLeadingZero(index + 1), dayValue: x };
        })
    );

    return { rawData, uniqueAvailableGenders, sortedEvents, sortedEventsWithFullNameCompetitors, indexedDays };
};

export const useMedallistsChannel = (channelName: Ref<string>) => {
    const rawData = useChannel<IMedalTableChannel>(channelName);
    return useMedalTableChannelData(rawData);
};
