<template>
    <teleport to="body">
        <Transition name="slide-in">
            <div v-if="open" class="lr__overlay">
                <div class="lr__overlay-container" ref="el">
                    <div class="lr_overlay-header">
                        <div class="overlay-title">
                            <slot name="header"></slot>
                        </div>
                        <LrButton class="lr__overlay-close-button" :icon="faTimes" @click="closeOverlay()"> </LrButton>
                    </div>
                    <div class="lr_overlay-content">
                        <slot></slot>
                    </div>
                </div>
                <div class="lr__overlay-background" @click="closeOverlay()"></div>
            </div>
        </Transition>
    </teleport>
</template>

<script setup lang="ts">
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { LrButton } from "lr-core";
import { watch } from "vue";

const props = withDefaults(
    defineProps<{
        open: boolean;
    }>(),
    { open: false }
);

const closeOverlay = () => {
    emit("update:open", false);
};

const emit = defineEmits(["update:open"]);

// add class to body for style-reasons (hide scrollbar on body)
watch(
    () => props.open,
    (open) => {
        const bodyClassList = document.querySelector("body").classList;
        if (open) {
            bodyClassList.add("x--overlay");
        } else {
            bodyClassList.remove("x--overlay");
        }
    }
);
</script>

<style lang="scss">
.lr__overlay {
    display: flex;
    position: fixed;
    align-items: flex-end;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: var(--layer-4);

    .lr__overlay-background {
        height: 100%;
        width: 100%;
        background-color: black;
        opacity: 0.5;
        transition: background-color 0.3s;
    }

    .lr__overlay-container {
        position: absolute;
        width: 95%;
        height: 95%;
        background-color: white;
        box-shadow: var(--shadow-2);
        z-index: var(--layer-5);
        padding: 0 20px 20px;
        overflow: auto;

        @include max-laptop {
            height: 98%;
        }

        @include max-mobile {
            padding: 0 10px 10px;
        }
    }

    .lr_overlay-header {
        font-size: 24px;
        min-height: 50px;
        padding-bottom: 20px;
        display: flex;
        margin-right: -20px;
        margin-left: -20px;
        padding-left: 20px;
        background-color: white;
        align-items: center;
        position: sticky;
        top: 0;
        z-index: var(--layer-2);

        @include max-mobile {
            padding-bottom: 10px;
            margin-right: -10px;
            margin-left: -10px;
            padding-left: 10px;
        }

        .overlay-title {
            flex: 1;
            border-bottom: 1px solid var(--secondary-3);
            margin-right: 95px; // space reserved for close button
            padding-bottom: 10px;
            padding-top: 10px;
            text-transform: uppercase;
            display: flex;
            flex-wrap: wrap;

            @include max-mobile {
                margin-top: 40px;
                margin-right: 0;
            }

            svg {
                &:first-child {
                    margin-right: 10px;
                    font-size: 22px;
                }
            }

            // specific use case with multiple text elements
            span + span {
                &::before {
                    content: "//";
                    padding: 0 5px;
                }
            }
        }

        .lr__overlay-close-button {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

.slide-in-enter-active {
    animation: var(--animation-slide-in-up);
    animation-duration: 0.4s;

    .lr__overlay-background {
        background-color: transparent;
    }
}

.slide-in-leave-active {
    animation: var(--animation-slide-out-down);
    animation-duration: 0.4s;

    .lr__overlay-background {
        background-color: transparent;
        transition: background-color 0s;
    }
}
</style>
