import {
    getCombatChannel,
    getCompDetailsChannel,
    getCompRecordsChannel,
    getGameChannel,
    getJudgementChannel,
    getRacquetChannel,
    getTimingChannel,
    ResultChannelTypes,
} from "stuffjs";
import { computed, Ref, unref } from "vue";

import { useLRStoreRefs } from "../store/lr-store";

export const useTournamentId = (): Ref<string> => useLRStoreRefs().tournamentId;
export const useTimingChannelName = (rsc: Ref<string> | string): Ref<string> =>
    computed((): string => getTimingChannel(useTournamentId().value, unref(rsc)));

export const useCombatChannelName = (rsc: Ref<string> | string): Ref<string> =>
    computed((): string => getCombatChannel(useTournamentId().value, unref(rsc)));

export const useCompDetailsChannelName = (rsc: Ref<string> | string): Ref<string> =>
    computed((): string => getCompDetailsChannel(useTournamentId().value, unref(rsc)));

export const useCompRecordsChannelName = (suffix: Ref<string> | string): Ref<string> =>
    computed((): string => getCompRecordsChannel(useTournamentId().value, unref(suffix)));

export const useResultChannelName = (resultChannelType: Ref<ResultChannelTypes>, rsc: Ref<string>) => {
    const resultChannelName = computed(() => {
        switch (resultChannelType.value?.toLowerCase()) {
            case ResultChannelTypes.TIMING:
                return getTimingChannel(useTournamentId().value, rsc.value);
            case ResultChannelTypes.COMBAT:
                return getCombatChannel(useTournamentId().value, rsc.value);
            case ResultChannelTypes.GAME:
                return getGameChannel(useTournamentId().value, rsc.value);
            case ResultChannelTypes.RACQUET:
                return getRacquetChannel(useTournamentId().value, rsc.value);
            case ResultChannelTypes.JUDGEMENT:
                return getJudgementChannel(useTournamentId().value, rsc.value);
            default:
                return undefined;
        }
    });
    return { resultChannelName };
};
