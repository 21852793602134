<template>
    <TransitionGroup class="container" :tag="tag" :name="transition" appear>
        <slot></slot>
    </TransitionGroup>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "VucTransitionList",
    props: {
        tag: {
            type: String,
            default: "tbody",
        },
        transition: {
            type: String as PropType<"t--list" | "t--list-grid" | "t--list-slide">,
            default: "t--list",
        },
    },
});
</script>

<style lang="scss">
.t--list-slide {
    &-move {
        transition: all 0.5s ease;
    }

    //enter
    &-enter-from {
        opacity: 0;
        transform: translateY(30px);
    }

    &-enter-active {
        transition: all 0.5s ease;
    }

    &-enter-to {
        opacity: 1;
    }

    //leave
    &-leave-from {
        opacity: 1;
    }

    &-leave-active {
        display: none;
    }

    &-leave-to {
        opacity: 0;
        transform: translateY(30px);
    }
}

.t--list {
    &-move,
    &-enter-active,
    &-leave-active {
        transition: all 0.5s;
    }

    &-enter-from,
    &-leave-to {
        opacity: 0;
        color: transparent;
    }

    &-leave-active {
        position: absolute;
    }
}

.t--list-grid {
    &-move {
        transition: transform 0.25s ease-in-out;
    }

    &-enter-active {
        opacity: 0;
        transition: all 0.2s;
    }

    &-leave-active {
        position: absolute;
        display: none;
    }

    &-leave,
    &-enter-to {
        opacity: 0;
    }
}
</style>
