import { useLRStoreRefs } from "lr-core";
import { defineStore, storeToRefs } from "pinia";
import {
    getCurrentEventChannel,
    getMedalTableChannel,
    IGlobalCurrentEventChannel,
    subscribeChannelContent,
} from "stuffjs";
import { computed } from "vue";

export const useEntryPageStore = defineStore("EntryPageStore", () => {
    const { state: lrState } = useLRStoreRefs();
    const currentChannelName = getCurrentEventChannel();

    // State
    const currentChannel = subscribeChannelContent<IGlobalCurrentEventChannel>(currentChannelName);

    // Getters
    const tournamentId = computed(() => currentChannel.value?.EventId);
    const medalChannelName = computed(() => {
        if (tournamentId.value) {
            return getMedalTableChannel(tournamentId.value);
        }
        return undefined;
    });
    const localConf = computed(() => lrState.value.config);

    // Actions

    // // Watchers
    // watch(connected, (connected) => {
    //     if (connected) {
    //         state.currentChannel = subscribeChannelContent<IGlobalCurrentEventChannel>(currentChannelName);
    //     }
    // });

    return { localConf, medalChannelName, tournamentId, currentChannel };
});

export const useEntryPageRefs = () => storeToRefs(useEntryPageStore());
