import { useBreakpoints } from "@vueuse/core";
import { computed } from "vue";

export const useCustomBreakpoints = () => {
    const breakpoints = useBreakpoints({
        // keep breakpoints in sync with css-definitions in 'mixins.scss'
        mobile: 480,
        tablet: 768,
        laptop: 1024,
        desktop: 1200,
    });

    const mobile = breakpoints.smaller("mobile");
    const tablet = breakpoints.between("mobile", "tablet");
    const minTablet = breakpoints.greaterOrEqual("tablet");
    const maxTablet = breakpoints.smaller("tablet");
    const laptop = breakpoints.between("tablet", "desktop");
    const desktop = breakpoints.greaterOrEqual("mobile");

    const breakPointsClassName = computed(() => {
        return {
            "x--is-mobile": mobile.value,
            "x--is-tablet": tablet.value,
            "x--is-laptop": laptop.value,
            "x--is-desktop": desktop.value,
        };
    });

    return {
        breakpoints,
        mobile,
        tablet,
        laptop,
        minTablet,
        maxTablet,
        desktop,
        breakPointsClassName,
    };
};
