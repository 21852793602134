<template>
    <div :class="['lr__component-table', `x--scroll-${scroll}`]">
        <slot v-if="displayPlaceHolder" ref="placeholder" name="placeholder"></slot>
        <template v-if="!displayPlaceHolder">
            <table>
                <caption v-if="hasCaption">
                    <slot name="caption" />
                </caption>
            </table>
            <div class="overflow-container" ref="overflowContainerRef">
                <table v-if="!displayPlaceHolder">
                    <thead v-if="hasHeader" ref="theadRef" :style="theadInlineStyle">
                        <slot name="header" />
                    </thead>

                    <VucTransitionList tag="tbody">
                        <slot name="body" />
                    </VucTransitionList>
                </table>
            </div>
            <table>
                <tfoot v-if="hasFooter">
                    <slot name="footer" />
                </tfoot>
            </table>
        </template>
    </div>
</template>

<script setup lang="ts">
import { useElementBounding } from "@vueuse/core";
import { computed } from "vue";
import { Ref, ref, useSlots } from "vue";
import { VucTransitionList } from "vue-uc";

const props = withDefaults(
    defineProps<{
        stickyThead?: boolean;
        displayPlaceHolder?: boolean;
        scroll?: "x" | "y" | "both";
    }>(),
    {
        stickyThead: false,
        displayPlaceHolder: false,
        scroll: "x",
    }
);

const slots = useSlots();
const hasCaption = computed(() => !!slots?.caption);
const hasHeader = computed(() => !!slots?.header);
const hasFooter = computed(() => !!slots?.footer);

const overflowContainerRef: Ref<HTMLElement> = ref(null);
const theadRef: Ref<HTMLElement> = ref(null);

const {
    top: overflowContainerTop,
    bottom: overflowContainerBottom,
    height: overflowContainerHeight,
} = useElementBounding(overflowContainerRef);
const { height: theadHeight } = useElementBounding(theadRef);

const theadInlineStyle = computed(() => {
    if (props.stickyThead && overflowContainerTop.value < 0 && overflowContainerBottom.value > 0) {
        const translateValue = Math.min(
            Math.abs(overflowContainerTop.value),
            overflowContainerHeight.value - theadHeight.value
        );
        const translateY = `translateY(${translateValue}px)`;

        return { transform: translateY };
    }
    return {};
});
</script>

<style lang="scss">
.lr__component-table {
    .overflow-container {
        overflow: hidden;
    }

    &.x--scroll-y {
        .overflow-container {
            overflow-y: auto;
        }
    }

    &.x--scroll-x {
        .overflow-container {
            overflow-x: auto;
        }
    }

    &.x--scroll-both {
        .overflow-container {
            overflow: auto;
        }
    }

    table {
        width: 100%;
        border-spacing: 0;
        font-family: var(--font-regular);

        caption {
            text-align: left;
            margin-bottom: 5px;
            margin-top: 15px;
            color: var(--font-color-1-contrast);
            font-family: var(--font-bold);
            text-transform: uppercase;
            font-size: 16px;
        }

        thead {
            background-color: white;
            z-index: var(--layer-1);

            @include max-tablet {
                z-index: initial;
            }

            tr {
                background-color: inherit;
            }
        }

        tbody {
            tr {
                &:nth-child(even) {
                    td {
                        background-color: var(--table-rows-even);
                    }
                }

                &:nth-child(odd) {
                    td {
                        background-color: var(--table-rows-odd);
                    }
                }
            }
        }

        thead,
        tbody {
            text-align: left;
            position: relative;
        }

        tr {
            height: 50px;

            td,
            th {
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 1px;
                padding: 10px 12px;
                line-height: 1.2;

                @include max-laptop {
                    padding: 6px 8px;
                }

                &.x--stick-left,
                &.x--stick-right {
                    position: sticky;
                    background-color: inherit;
                    z-index: var(--layer-1);
                }
            }

            th {
                font-family: var(--font-bold);
                text-transform: uppercase;
            }

            td {
                border-top: 3px solid var(--bg-4);
                position: relative; // for tool tip positioning
            }
        }
    }

    .lr__place-holder {
        padding: 40px;
    }
}
</style>
