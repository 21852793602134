<template>
    <td class="medal-count column-section">{{ gold }}</td>
    <td class="medal-count">{{ silver }}</td>
    <td class="medal-count">{{ bronze }}</td>
    <td class="medal-count">{{ total }}</td>
</template>

<script lang="ts" setup>
import { IMedalCompetitor } from "stuffjs";
import { computed, PropType } from "vue";

const props = defineProps({
    competitor: {
        type: Object as PropType<IMedalCompetitor>,
        default: (): IMedalCompetitor => {
            return {};
        },
    },
    prefix: String as PropType<"M" | "W" | "X" | "All">,
});
const stats = computed(() => props.competitor?.Stats);

const bronze = computed(() => stats.value?.[`${props.prefix}_Bronze`]);
const silver = computed(() => stats.value?.[`${props.prefix}_Silver`]);
const gold = computed(() => stats.value?.[`${props.prefix}_Gold`]);
const total = computed(() => stats.value?.[`${props.prefix}_Total`]);
</script>

<style lang="scss" scoped>
.medal-count {
    text-align: center;
    &:empty {
        &:after {
            content: "0";
            opacity: 0.3;
        }
    }
}
</style>
