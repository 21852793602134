import "lr-core/src/styles/base.scss";
import "lr-core/src/styles/themes/scog2022.scss";

import { registerAppModules, useLRStore, useStickyLeftDirective } from "lr-core";
import eventFavIconUrl from "lr-core/src/assets/favicon.png";
import { handleWarn, SportTypes } from "stuffjs";

import App from "./App.vue";
import { routes } from "./routes";

const link: HTMLLinkElement = document.querySelector("link[rel~='icon']");
link.href = eventFavIconUrl;

const { application } = registerAppModules({
    title: "Liveresults Combat Games 2023",
    rootApp: App,
    routes,
    translations: {},
});
useStickyLeftDirective(application);

const { initStore } = useLRStore();
await initStore({ sportCode: "GLO", sportType: SportTypes.None, name: "Global" }).catch(handleWarn);
application.mount("#app");

// Log the Version of the product
declare const APP_VERSION;
console.info(`Entry-Page Version: ${APP_VERSION}`);
