<template>
    <th class="nat--default">
        <slot>{{ $getStrByLng().HEADER_NAT }}</slot>
    </th>
</template>

<script setup lang="ts"></script>

<style>
th.nat--default {
    text-align: left;
}
</style>
